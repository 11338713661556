import base from './base'
import development from './development'
import production from './production'

const config = {
  ...base,
  ...(process.env.REACT_APP_ENV === 'production' ? production : development)
}

export default config
