import { useState } from 'react'
import {
  useListContext,
  TopToolbar,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useNotify,
  ToolbarProps
} from 'react-admin'
import ArchiveIcon from '@material-ui/icons/Archive'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getSettlementReportAsZip } from '@services/settlement-report'

type SettlementReportTopToolbarProps = ToolbarProps & {
  maxResults?: number
}

const SettlementReportTopToolbar = (props: SettlementReportTopToolbarProps) => {
  const notify = useNotify()
  const { className, maxResults, ...rest } = props
  const { currentSort, resource, filterValues, total } = useListContext()
  const [ inProgress, setInProgress ] = useState(false)

  async function handleDownloadClick() {
    setInProgress(true)
    try {
      await getSettlementReportAsZip(filterValues.operator, filterValues.reportMonth)
    } catch (e) {
      console.error(e)
      notify('Error while trying to download zip file. Try again later', 'warning')
    } finally {
      setInProgress(false)
    }
  }

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        label='Export table as CSV'
      />
      <Button
        onClick={ handleDownloadClick }
        disabled={ total === 0 || inProgress }
        label='Download full log as zip'>
        { inProgress ? <CircularProgress size={20} /> : <ArchiveIcon /> }
      </Button>
    </TopToolbar>
  )
}

export default SettlementReportTopToolbar
