import Select, { SelectProps } from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import { Operator } from '@services/operator'
import { Permissions } from '@services/permissions'

import { useViewOperator } from '@hooks/useViewOperator'

function CampaignOperatorSelector() {
  const { operator, setOperator, viewOperators } = useViewOperator(Permissions.VIEW_CAMPAIGN)

  const handleChange: SelectProps['onChange'] = event => {
    setOperator(event.target.value as Operator)
  }

  return (
    <FormControl variant='outlined' size='small' style={{minWidth: 120}}>
      <InputLabel id='operator-selector-label'>Operator</InputLabel>
      {operator && (
        <Select labelId='operator-selector-label' label='Operator' value={operator} onChange={handleChange}>
          {viewOperators.map((operator, index) =>
            <MenuItem key={`${operator}-${index}`} value={operator}>{operator.toUpperCase()}</MenuItem>
          )}
        </Select>
      )}
    </FormControl>
  )
}

export default CampaignOperatorSelector
