import { defaultTheme } from 'react-admin'
import color from 'color'
import clsx from 'clsx'
import { format as formatDate } from 'date-fns'
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core'

const themeOverrides = createTheme(defaultTheme, {
  overrides: {
    MuiInputBase: {
      root: {
        color: color('#fff').alpha(0.75).rgb().string(),
        '&$focused': {
          color: '#fff'
        },
      }
    },
    MuiFormLabel: {
      root: {
        color: color('#fff').alpha(0.35).rgb().string(),
        '&$focused': {
          color: color('#fff').alpha(0.95).rgb().string()
        },
        '.inputFocused &': {
          color: color('#fff').alpha(0.95).rgb().string()
        },
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: color('#fff').alpha(0.87).rgb().string()
        },
        '&$focused $notchedOutline': {
          borderColor: color('#fff').alpha(0.87).rgb().string()
        },

        '.inputFocused & $notchedOutline': {
          borderColor: color('#fff').alpha(0.87).rgb().string()
        },

      },
      adornedEnd: {
        paddingRight: 0
      }
    }
  },
  palette: {
    type: 'dark',
  }
})

const useStyles = makeStyles((theme) => ({
  pickerToolbar: {
    padding: 15,
    maxWidth: 310,
    boxSizing: 'border-box',
    display: 'flex',
    background: theme.palette.primary.main
  }
}))

type RangeDateToolbarComponentProps = {
  firstDay: Date|null
  lastDay: Date|null,
  currentSelection: 'first' | 'last',
  setFirstDayActive: () => void,
  setLastDayActive: () => void,
  clearFirstDay: () => void,
  clearLastDay: () => void 
}

const RangeDateToolbarComponent = ({
  firstDay,
  lastDay,
  currentSelection,
  setFirstDayActive,
  setLastDayActive,
  clearFirstDay,
  clearLastDay 
}: RangeDateToolbarComponentProps) => {

  const inputValueLeft = firstDay ? formatDate(firstDay, 'MMM d') : 'Choose first date'
  const inputValueRight = lastDay ? formatDate(lastDay, 'MMM d') : 'Choose last date'
  const classes = useStyles()

  const leftInputClasses = clsx({ inputFocused: currentSelection === 'first'})
  const rightInputClasses = clsx({ inputFocused: currentSelection === 'last' })

  return (
    <div className={classes.pickerToolbar}>
      <ThemeProvider theme={themeOverrides}>
        <TextField
          label='Start date'
          value={inputValueLeft}
          variant='outlined'
          style={{marginRight: 5}}
          onClick={() => {
            setFirstDayActive()
          }}
          className={leftInputClasses}
          InputProps={{
            readOnly: true,
            endAdornment: <IconButton aria-label='Clear start date' onClick={clearFirstDay}>
              <HighlightOffIcon style={{color: '#fff'}} />
            </IconButton>
          }} />
        <TextField
          label='End date'
          value={inputValueRight}
          variant='outlined'
          style={{marginLeft: 5}}
          onClick={() => {
            setLastDayActive()
          }}
          className={rightInputClasses}
          InputProps={{
            readOnly: true,
            endAdornment: <IconButton aria-label='Clear end date' onClick={clearLastDay}>
              <HighlightOffIcon style={{color: '#fff'}} />
            </IconButton>
          }} />
      </ThemeProvider>
    </div>
  )
}

export default RangeDateToolbarComponent
