import { Operator } from '@services/operator'
import { Metric } from '@services/campaigns'

import {
  REQUEST_LIST,
  RECEIVE_LIST,
  ERROR_LIST,
  REQUEST_METRICS_OVERALL,
  RECEIVE_METRICS_OVERALL,
  ERROR_METRICS_OVERALL,
  type CampaignActionPaylods,
} from '@actions/campaigns'

export type CampaignReducerState = {
  inProgress: number
  hasError: boolean,
  campaignList: unknown[],
  operator?: Operator,
  campaignMetrics: {
    arpu: Metric,
    offerCount: Metric,
    purchaseCount: Metric,
    revenue: Metric,
    visitCr: Metric,
    currency: Metric,
  }
}

const initialMetric = {
  description: '',
  name: '',
  value: '',
}

const initialState: CampaignReducerState = {
  inProgress: 0,
  hasError: false,
  campaignList: [],
  campaignMetrics: {
    arpu: { ...initialMetric },
    offerCount: { ...initialMetric },
    purchaseCount: { ...initialMetric },
    revenue: { ...initialMetric },
    visitCr: { ...initialMetric },
    currency: { ...initialMetric }
  }
}

export default function campaignsReducer(state = initialState, actionPayload: CampaignActionPaylods) {
  // TODO all actions should use only "payload" not a mix of payload/error/catalog !
  const { type } = actionPayload
  if(type === REQUEST_LIST) {
    return {
      ...state,
      operator: actionPayload.payload.operator,
      inProgress: state.inProgress + 1
    }
  }

  if(type === RECEIVE_LIST) {
    return {
      ...state,
      inProgress: state.inProgress - 1,
      campaignList: actionPayload.payload
    }
  }

  if(type === ERROR_LIST) {
    return {
      ...state,
      inProgress: state.inProgress - 1,
      hasError: true
    }
  }

  if(type === REQUEST_METRICS_OVERALL) {
    return {
      ...state,
      operator: actionPayload.payload.operator,
      inProgress: state.inProgress + 1
    }
  }

  if(type === RECEIVE_METRICS_OVERALL) {
    return {
      ...state,
      inProgress: state.inProgress - 1,
      campaignMetrics: actionPayload.payload
    }
  }

  if(type === ERROR_METRICS_OVERALL) {
    return {
      ...state,
      inProgress: state.inProgress - 1,
      hasError: true
    }
  }

  return state
}
