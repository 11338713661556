import { ToolbarProps, TopToolbar, sanitizeListRestProps } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { CSSProperties } from 'react'

import CatalogVersion from '@components/catalog/CatalogVersion'
import CatalogUpload from '@components/catalog/CatalogUpload'
import CatalogRollback from '@components/catalog/CatalogRollback'

const useStyles = makeStyles({
  lastVersion: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'] as unknown as CSSProperties['fontFamily'],
    fontSize: '0.8125rem',
    padding: [[4, 5]] as unknown as CSSProperties['padding'],
    color: '#8a8a8a',
    height: 21,
    display: 'flex',
    alignItems: 'center'
  }
})

const CatalogTopToolbar = (props: ToolbarProps) => {
  const { className, ...rest } = props
  const classes = useStyles()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <div className={classes.lastVersion}>
        previous version uploaded on 
        <CatalogVersion
          type='previous'
          width={68}
          spinnerSize={14}
          spinnerPadding={[[0, 29, 0, 28]]}
        />
      </div>
      <CatalogRollback />
      <CatalogUpload />
    </TopToolbar>
  )
}

export default CatalogTopToolbar
