import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  ToolbarProps
} from 'react-admin'

type SettlementReportTopToolbarProps = ToolbarProps & {
  maxResults?: number
}

const MissingCallbacksReportTopToolbar = (props: SettlementReportTopToolbarProps) => {
  const { className, maxResults, ...rest } = props
  const { currentSort, resource, filterValues, total } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        label='Export table as CSV'
      />
    </TopToolbar>
  )
}

export default MissingCallbacksReportTopToolbar
