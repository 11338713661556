import parseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'

import changeLogFile from './changelog.json'

const KEY = 'cl_seen'
const MEM_LIMIT = 10
const SHOW_LIMIT = 3
const DATE_FORMAT = 'dd.MM.y'

const getStoredDates = () => JSON.parse(localStorage.getItem(KEY) || '[]') as string[]

export function getUnseen() {
  const seen = getStoredDates()

  return changeLogFile
    .slice(-1 * SHOW_LIMIT)
    .filter(({ date }) => !seen.includes(date))
    .reverse()
}

export function markSeen(date: string) {
  const seen = getStoredDates()

  seen.push(date)

  const seenSorted = seen
    .map((date: string): Date => parseDate(date, DATE_FORMAT, new Date()))
    .sort((a: Date, b: Date) => Number(a) - Number(b))

  if(seenSorted.length > MEM_LIMIT) {
    seenSorted.pop()
  }

  const output = seenSorted.map((date: Date) => formatDate(date, DATE_FORMAT))

  localStorage.setItem(KEY, JSON.stringify(output))
}
