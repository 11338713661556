import { getUserGroups, GroupType } from '@services/okta'

export enum Permissions {
  VIEW_SETTLEMENT_REPORT = 'VIEW_SETTLEMENT_REPORT',
  VIEW_PURCHASE_LOOKUP = 'VIEW_PURCHASE_LOOKUP',
  VIEW_CATALOG = 'VIEW_CATALOG',
  VIEW_CAMPAIGN = 'VIEW_CAMPAIGN'
}

const ALL_PERMISSIONS = Object.values(Permissions)

export type GroupPermission = Record<GroupType, Permissions[]>

export const GROUPS: GroupPermission = {
  SUPER_ADMIN: [
    ...ALL_PERMISSIONS
  ],
  GP: [
    Permissions.VIEW_SETTLEMENT_REPORT,
  ],
  DIGI: [
    Permissions.VIEW_PURCHASE_LOOKUP,
    Permissions.VIEW_CATALOG,
    Permissions.VIEW_CAMPAIGN,
    Permissions.VIEW_SETTLEMENT_REPORT,
  ],
  DTAC: [
    Permissions.VIEW_PURCHASE_LOOKUP,
    Permissions.VIEW_CATALOG,
    Permissions.VIEW_CAMPAIGN,
    Permissions.VIEW_SETTLEMENT_REPORT,
  ],
  INDOSAT: [
    Permissions.VIEW_PURCHASE_LOOKUP,
    Permissions.VIEW_CATALOG,
    Permissions.VIEW_SETTLEMENT_REPORT,
    Permissions.VIEW_CAMPAIGN,
  ],
  TRI: [
    Permissions.VIEW_PURCHASE_LOOKUP,
    Permissions.VIEW_CATALOG,
    Permissions.VIEW_SETTLEMENT_REPORT,
    Permissions.VIEW_CAMPAIGN,
  ],
}

export function hasAny(right: Permissions, groupPermissions?: GroupPermission): boolean {
  if (!groupPermissions) return false

  return Object.values(groupPermissions)
    .flat()
    .some(permission => permission === right)
}

export async function getPermissions(): Promise<GroupPermission> {
  const userGroups = await getUserGroups()
  if (userGroups.includes('SUPER_ADMIN')) return GROUPS

  const groupEntries = Object.entries(GROUPS)
  const allowedGroupEntries = groupEntries.filter(([groupName]) => userGroups.includes(groupName as GroupType))

  return Object.fromEntries(allowedGroupEntries) as GroupPermission
}
