import { makeStyles } from '@material-ui/core/styles'

import { DEV_BRANCH, DEV_BUILD_DATE, DEV_GIT_SHA } from '@services/dev'

const useStyles = makeStyles({
  element: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 2,
    fontSize: 14,
    color: '#aaa',
    fontFamily: 'Helvetica'
  },
  clickable: {
    cursor: 'pointer'
  }
})

export function copy(text: string) {
  const type = 'text/plain'
  const blob = new Blob([text], { type })
  const item = new ClipboardItem({ [type]: blob })

  navigator.clipboard.write([item])
}

const DebugComponent = () => {
  const classes = useStyles()
  const isDeveloper = localStorage.getItem('developer')

  function copyCommitSha() {
    copy(DEV_GIT_SHA)
  }

  function copyBranchName() {
    copy(DEV_BRANCH)
  }

  return isDeveloper
    ? (
      <div className={classes.element}>
        <span>Built from
          <strong className={classes.clickable} onClick={copyBranchName}>{ DEV_BRANCH }</strong>
          <code className={classes.clickable} onClick={copyCommitSha}>{ DEV_GIT_SHA.slice(0, 7) }</code> on { DEV_BUILD_DATE }
        </span>
      </div>
    )
    : null
}

export default DebugComponent
