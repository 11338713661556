import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNotify, useRefresh, useListContext, Button as RAButton } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ErrorIcon from '@material-ui/icons/Error'
import redColor from '@material-ui/core/colors/red'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import CircularProgress from '@material-ui/core/CircularProgress'

import { rollbackCatalog } from '@services/catalog'

import { catalogSelector } from '@selectors/catalogSelector'

import { formatCatalogVersion } from '@components/catalog/CatalogVersion'

import { FilterListContext } from './CatalogFilters'

const useStyles = makeStyles({
  dialogTitle: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    right: 20,
    top: 12
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20
  },
  confirmButton: {
    backgroundColor: redColor[700],
    '&:hover': {
      backgroundColor: redColor[900],
    },
  }
})

export default function CatalogRollback() {
  const classes = useStyles()
  const refreshList = useRefresh()
  const { filterValues: { operator, catalog }} = useListContext() as FilterListContext
  const notify = useNotify()
  const { previousVersion } = useSelector(catalogSelector)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  function confirm() {
    setInProgress(true)
    rollbackCatalog(operator, catalog)
      .then(() => {
        setInProgress(false)
        setIsDialogOpen(false)
        notify('Catalog has been reverted successfully', 'success')

        setTimeout(() => {
          refreshList()
        }, 250)
      })
      .catch(error => {
        setInProgress(false)
        console.log(error)
        notify('Error while reverting catalog. Try again later', 'error')
      })
  }

  return (
    <div>
      <RAButton
        label='Rollback'
        onClick={() => {setIsDialogOpen(true)}}>
        <RestorePageIcon />
      </RAButton>
      <Dialog
        open={isDialogOpen}
        onClose={(event, reason) => { if(reason === 'backdropClick') { setIsDialogOpen(false) }}}
        maxWidth='xs'>
        <DialogTitle className={classes.dialogTitle}>
                Rollback catalog
          {inProgress ? <CircularProgress className={classes.progress} /> : '' }
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.icon}>
            <ErrorIcon style={{ fontSize: 80, color: redColor[300] }} />
          </div>
          <Typography variant='body1' component='div'>
            Are you sure you want to rollback to version uploaded on 
            <strong>{ formatCatalogVersion(previousVersion, 'dd-MM-yyyy H:mm')}</strong>
            ? The latest version will be removed without backup.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button disabled={inProgress} className={classes.confirmButton} variant='contained' color='primary' onClick={confirm} disableElevation>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
