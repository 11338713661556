import { useSelector } from 'react-redux'
import { Theme, useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources, usePermissions, MenuProps } from 'react-admin'
import DefaultIcon from '@material-ui/icons/ViewList'
import HomeIcon from '@material-ui/icons/Home'
import InsertChartIcon from '@material-ui/icons/InsertChart'

import * as permissionsService from '@services/permissions'

type CampaignState = {
  admin: {
    ui: {
      sidebarOpen: boolean
      viewVersion: string
    }
  }
}

const Menu = ({ onMenuClick, logout }: MenuProps) => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state: CampaignState) => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const { permissions } = usePermissions<permissionsService.GroupPermission>()

  return (
    <div>
      <MenuItemLink
        to='/'
        primaryText='Home'
        leftIcon={<HomeIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources.filter(resource => resource.options?.showMenu !== false).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.label) || resource.name}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {permissionsService.hasAny(permissionsService.Permissions.VIEW_CAMPAIGN, permissions) && (
        <MenuItemLink
          to='/campaigns'
          primaryText='Campaigns'
          leftIcon={<InsertChartIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {isXSmall && logout}
    </div>
  )
}

export default Menu
