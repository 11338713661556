import {
  REQUEST_VERSIONS,
  RECEIVE_VERSIONS,
  VERSIONS_ERROR,
  REQUEST_CATALOGS,
  RECEIVE_CATALOGS,
  CATALOGS_ERROR,
  type CatalogActionPayloads
} from '@actions/catalog'

export type CatalogReducerState = {
  inProgress: boolean,
  hasError: false,
  previousVersion: string,
  currentVersion: string,
  operatorCatalogs: string[]
}

const CatalogReducerInitialState: CatalogReducerState = {
  inProgress: false,
  hasError: false,
  previousVersion: '',
  currentVersion: '',
  operatorCatalogs: ['default']
}

export default function catalogReducer(state = CatalogReducerInitialState, action: CatalogActionPayloads) {
  const { type } = action

  if(type === REQUEST_VERSIONS) {
    return {
      ...state,
      inProgress: true
    }
  }

  if(type === RECEIVE_VERSIONS) {
    return {
      ...state,
      ...action.payload,
      inProgress: false
    }
  }

  if(type === VERSIONS_ERROR) {
    return {
      ...state,
      inProgress: false,
      hasError: true
    }
  }

  if(type === REQUEST_CATALOGS) {
    return {
      ...state,
      inProgress: true
    }
  }

  if(type === RECEIVE_CATALOGS) {
    return {
      ...state,
      ...action.payload,
      inProgress: false
    }
  }

  if(type === CATALOGS_ERROR) {
    return {
      ...state,
      inProgress: false,
      hasError: true
    }
  }

  return state
}
