import { Operator } from '@services/operator'

import { ActionPayload } from '@actions/types'

export const REQUEST_VERSIONS = 'CATALOG/REQUEST_VERSIONS'
export const RECEIVE_VERSIONS = 'CATALOG/RECEIVE_VERSIONS'
export const VERSIONS_ERROR = 'CATALOG/VERSIONS_ERROR'
export const REQUEST_CATALOGS = 'CATALOG/REQUEST_CATALOGS'
export const RECEIVE_CATALOGS = 'CATALOG/RECEIVE_CATALOGS'
export const CATALOGS_ERROR = 'CATALOG/CATALOGS_ERROR'

// TODO refactor this is not a way of using redux actions! type / payload should be used as standard. Reducer does the rest
// TODO use enums for actions instead of string

export type RequestVersionActionPayload = ActionPayload<'CATALOG/REQUEST_VERSIONS', { operator: Operator, catalog: string}>

export const requestVersions = (operator: Operator, catalog: string = 'default'): RequestVersionActionPayload => {
  return {
    type: REQUEST_VERSIONS,
    operator,
    catalog
  }
}

export type ReceiveVersionsActionPayload = ActionPayload<'CATALOG/RECEIVE_VERSIONS', { payload: object }>

export const receiveVersions = (payload: object): ReceiveVersionsActionPayload => {
  return {
    type: RECEIVE_VERSIONS,
    payload
  }
}

export type VersionsErrorActionPayload = ActionPayload<'CATALOG/VERSIONS_ERROR', { error: Error }>

export const versionsError = (error: Error): VersionsErrorActionPayload => {
  return {
    type: VERSIONS_ERROR,
    error
  }
}

export type RequestCatalogActionPayload = ActionPayload<'CATALOG/REQUEST_CATALOGS', { operator: Operator }>

export const requestCatalogs = (operator: Operator): RequestCatalogActionPayload => {
  return {
    type: REQUEST_CATALOGS,
    operator
  }
}

export type ReceiveCatalogActionPayload = ActionPayload<'CATALOG/RECEIVE_CATALOGS', { payload: object }>

export const receiveCatalogs = (payload: object): ReceiveCatalogActionPayload => {
  return {
    type: RECEIVE_CATALOGS,
    payload
  }
}

export type CatalogErrorActionPayload = ActionPayload<'CATALOG/CATALOGS_ERROR', { error: Error }>

export const catalogsError = (error: Error): CatalogErrorActionPayload => {
  return {
    type: CATALOGS_ERROR,
    error
  }
}

export type CatalogActionPayloads = 
  CatalogErrorActionPayload |
  ReceiveCatalogActionPayload |
  RequestCatalogActionPayload |
  VersionsErrorActionPayload |
  ReceiveVersionsActionPayload |
  RequestVersionActionPayload 
