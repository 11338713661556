import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    color: '#fff',
    margin: 5
  },
  google: {
    background: '#DB4437'
  },
  'facebook-cp': {
    background: '#2816c8'
  },
  facebook: {
    background: '#4267B2'
  },
  unknown: {
    color: '#101010'
  }
})

type ChannelName = 'google' | 'facebook-cp' | 'facebook' | 'unknown'

type ChannelsFieldProps = { 
  source: string, 
  record?: Record<string, { name: ChannelName }[]>, 
  sortable?: boolean 
}

export function ChannelsField({source, record = {}}: ChannelsFieldProps) {
  const classes = useStyles()
  const channels = record[source]

  return (
    <div className={classes.chips}>
      {channels?.map(({name}, idx) => (
        <Chip
          key={`${name}-${idx}`}
          className={clsx([classes.chip, classes[name.toLowerCase() as ChannelName ]])}
          size='small'
          label={name} />
      ))}
    </div>
  )
}
