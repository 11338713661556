import { AccessToken } from '@okta/okta-auth-js'
import { HttpError } from 'react-admin'

import { authClient, TOKEN_ACCESS } from '@services/okta'

async function request(url: string, options: RequestInit) {
  let token

  try {
    ({ accessToken: token } = await (authClient.tokenManager.get(TOKEN_ACCESS)) as AccessToken)
  } catch(e) {
    return Promise.reject({ status: 401 })
  }

  const headers = new Headers({
    authorization: `Bearer ${token}`,
    ...options.headers
  })

  delete options.headers

  return fetch(url, { headers, ...options })
}

async function jsonRequest<T = unknown>(url: string, options: RequestInit) {
  const response = await request(url, options)

  if(response.ok) {
    return response.json() as T
  } else {
    throw new HttpError(
      response.statusText,
      response.status,
      await response.text()
    )
  }
}

// TODO REST methods should be generic get<T> where T = response type!
const httpService = {
  get: <T = any>(url: string, options?: RequestInit) => {
    const opt = {
      method: 'GET',
      ...options,
    }

    return jsonRequest<T>(url, opt)
  },
  post: (url: string, body?: string | FormData, options?: RequestInit) => {
    const opt = {
      method: 'POST',
      body,
      ...options,
    }
    return jsonRequest(url, opt)
  },
  download: async (url: string, { fileName, ...rest }: { fileName: string } & RequestInit) => {
    const response = await request(url, rest)

    if(response.ok) {
      const blob = await response.blob()

      const url = window.URL.createObjectURL(
        new Blob([blob]),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        body: await response.text()
      })
    }
  }
}

export default httpService
