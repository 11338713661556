import { List, useListParams } from 'react-admin'

import MissingCallbacksReportTopToolbar from '@components/MissingCallbacksReportTopToolbar'

import { MissingCallbacksGrid } from './MissingCallbacksGrid'

const MissingCallbacksList = () => {
  const [{ filter }] = useListParams({ resource: 'summary', syncWithLocation: true })

  return (
    <div>
      <List
        basePath='/missingCallbacks'
        title=' & Missing Callbacks'
        resource='missingCallbacks'
        hasList={true}
        filterDefaultValues={filter}
        filter={filter}
        syncWithLocation={false}
        actions={<MissingCallbacksReportTopToolbar />}
        bulkActionButtons={false}
        pagination={false}
        sort={{ field: 'channel', order: 'ASC' }}
      >
        <MissingCallbacksGrid />
      </List>
    </div>
  )
}

export default MissingCallbacksList
