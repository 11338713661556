import { AppBar, AppBarProps } from 'react-admin'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import millomLogo from './millom-logo.png'

const useStyles = makeStyles({
  bar: {
    background: '#0F284C'
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '12px'
  },
  heading: {
    flex: 1
  },
})

const CustomAppBar = (props: AppBarProps) => {
  const classes = useStyles()
  return (
    <AppBar {...props} className={classes.bar}>
      <Typography
        variant='h6'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />
      <div className={classes.heading}>
        <Link to='/'>
          <img src={millomLogo} height='30' alt='MillomLogo' />
        </Link>
      </div>
    </AppBar>
  )
}

export default CustomAppBar
