import {PropsWithChildren, createContext, useCallback, useContext, useEffect, useState} from 'react'

import { GroupType, getUserGroups } from '@services/okta'
import { authClient } from '@services/okta'
import { Permissions} from '@services/permissions'

export type Operator = 'digi' | 'dtac' | 'indosat' | 'tri' | 'gp'
const OPERATOR_LIST: Operator[] = ['digi','dtac','indosat','tri','gp']

export const getListForSelect = (operators: Operator[] = []) => operators.map(operator => ({
  id: operator,
  name: operator.toUpperCase()
}))

export const getOperators = async (): Promise<Operator[]> => {
  const userGroups = await getUserGroups()
  if (userGroups.includes('SUPER_ADMIN')) return OPERATOR_LIST

  return OPERATOR_LIST.filter(operator => userGroups.includes(operator.toUpperCase() as GroupType))  
}

type OperatorContextType = {
  operators: Operator[],
  getOperatorForView: (permission: Permissions) => Operator|undefined,
  setOperatorForView: (permission: Permissions, operator: Operator) => void
}

export const OperatorContext = createContext<OperatorContextType>({ 
  operators: [],
  getOperatorForView: () => undefined,
  setOperatorForView: () => {}
})

export function useProvideOperator() {
  const [operators, setOperators] = useState<Operator[]>([])
  const [viewOperator, setOperatorPerView] = useState<Partial<Record<Permissions, Operator>>>({})
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    authClient.authStateManager.subscribe((state: { isAuthenticated: boolean} | undefined) => {
      if (state?.isAuthenticated) {
        setLoggedIn(true)
      }
    })
  }, [])

  useEffect(() => {
    getOperators().then(operators => {
      setOperators(operators)
    })
  }, [loggedIn])

  const setOperatorForView = useCallback((permission: Permissions, operator: Operator) =>
    setOperatorPerView({
      ...viewOperator,
      [permission]: operator
    }),
  [viewOperator])
  
  const getOperatorForView = useCallback((permission: Permissions): Operator|undefined => {
    const currentOperator = viewOperator[permission]
    return currentOperator
  },
  
  [viewOperator])

  return {
    operators,
    setOperatorForView,
    getOperatorForView
  }
}

export const useOperatorContext = () => useContext(OperatorContext)

export const OperatorProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useProvideOperator()

  return (
    <OperatorContext.Provider value={data}>
      {children}
    </OperatorContext.Provider>
  )
}
