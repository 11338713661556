import { RouteComponentProps } from 'react-router-dom'
import { Authenticated } from 'react-admin'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import ChangeLog from '@components/ChangeLog'

const useStyles = makeStyles({
  container: {
    width: '70%',

    '@media (max-width: 320px)': {
      width: '100%',
      maxWidth: 300
    }
  },
  heading: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 14,
    fontWeight: 100,
    fontSize: '3em',
    textAlign: 'center'
  },
  helloCard: {
    marginBottom: 10
  },
  documentationText: {
    marginTop: 10
  }
})

type HomeComponentProps = RouteComponentProps

const HomeComponent = ({ location }: HomeComponentProps) => {
  const classes = useStyles()

  return (
    <Authenticated location={location}>
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <div className={classes.container}>
          <h1 className={classes.heading}>MNO Portal</h1>
          <Card className={classes.helloCard}>
            <CardContent>
              <Typography variant='body2' component='p'>
                  This portal is a self-service tool for the Millom platform.
                  We would love to heard you feedback.
                  If you have questions or comments please write to&nbsp;
                <Link href='mailto:wuri.rostandy@telenorlinx.com'>wuri.rostandy@telenorlinx.com</Link>.
              </Typography>
              <Typography className={classes.documentationText} variant='body2' component='p'>
                You can find documentation&nbsp;
                <Link href='https://drive.google.com/file/d/1Pzo208OtaE-ZF8180cqArZZDqMgAo1mf/view?usp=sharing'>here</Link>
              </Typography>
            </CardContent>
          </Card>
          <ChangeLog />
        </div>
      </Box>
    </Authenticated>
  )
}

export default HomeComponent
