import { GetListParams, GetListResult, HttpError, Record as RecordAdminType} from 'react-admin'
import { parseISO as parseDate } from 'date-fns'

import { emptyResponse } from '@providers/data-provider'

import http from '@services/http'
import config from '@services/config'

import { Operator } from './operator'

interface GetPurchaseReportOpts extends GetListParams {
  filter: {
    id: string,
    dateRange: string,
    operator: Operator
  }
}

export async function getPurchaseReport(params: GetPurchaseReportOpts): Promise<GetListResult<RecordAdminType>> {
  if (!params.filter.id) {
    return emptyResponse()
  }

  const query = new URLSearchParams(Object.assign({},
    resolveIdType(params.filter.id),
    resolvePurchaseReportDate(params.filter.dateRange)
  ))

  const url = `${config.api.url}/lookup/v1/${params.filter.operator}/purchases?${query.toString()}`

  return http.get(url)
    .then(response => response.map(purchaseReportMapper))
    .then(mapped => ({
      data: mapped,
      total: mapped.length
    }))
    .catch(error => {
      if(error.status === 400) {
        return Promise.reject(new HttpError('Invalid ID', 400))
      }
      return Promise.reject(error)
    })
}

type ReportItem = { status: 'ok' | 'fail', sendAt: string }

function purchaseReportMapper(item: ReportItem, index: number): RecordAdminType {
  const statusMap: Record<ReportItem['status'], boolean> = {
    ok: true,
    fail: false
  }

  const mappedReport = {
    ...item,
    status: statusMap[item.status.toLowerCase() as ReportItem['status']],
    sendAt: parseDate(item.sendAt),
  }

  return {
    id: index,
    ...mappedReport
  }
}

const isMSISDRegExp = /^\d+$/g

function resolveIdType(id: string) {
  const output = {
    idType: 'MSISDN',
    idValue: id
  }

  if(!isMSISDRegExp.test(id)) {
    output.idType = 'CPID'
  }

  return output
}

function resolvePurchaseReportDate(range: string = ''): { fromDate: string, toDate: string} | null {
  const [fromDate, toDate] = range.split('_')

  return fromDate && toDate ? { fromDate, toDate } : null
}
