import {
  Datagrid,
  TextField,
  NumberField,
  DatagridProps,
  useListContext,
  Empty,
} from 'react-admin'
import { Record} from 'ra-core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { Operator } from '@services/operator'

import { checkEmptyDataForFields } from './SettlementGrid'

type OperatorFilterProps = { filterValues?: { operator: Operator } }
type SettlementReportDatagridProps = DatagridProps & OperatorFilterProps

export const MissingCallbacksGrid = (props: SettlementReportDatagridProps) => {
  const { data, resource } = useListContext(props)
  const { filterValues: { operator } } = props as DatagridProps & Required<OperatorFilterProps>

  const dataExistsForField = checkEmptyDataForFields(
    [
      'feePerUser', 
      'total',
      'revenueIncludingTax',
      'revenueExcludingTax',
      'revenueFee',
      'tax',
      'pgiFee',
      'netRevenueFee',
      'monthlyPayingUsers'
    ],
    data
  )

  const commissionField = 'revenueFee'
  const moneyFormatOptions = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  const rowStyle = ({ isSummaryRow, isGrandTotal }: Record, _idx: number) => {
    const backgroundColor = isSummaryRow
      ? '#DCDCDC'
      : isGrandTotal ? '#0F284C' : undefined
    
    const color = isGrandTotal ? 'white' : 'inherit'

    return {
      backgroundColor,
      color,
    }
  }

  return (
    <div>
      <Box m={2} style={{width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'flexStart'}}>
        <Typography variant='h5'>Missing Callbacks</Typography>
      </Box>
      <Datagrid 
        {...props}
        resource={resource}
        empty={<Empty resource={resource} />}
        rowStyle={rowStyle}
      >
        <TextField source='channel' />
        <NumberField source='fundsource' label='Fund source' />
        <NumberField source='missingCallbacks' label='Missing Callbacks' />
        {dataExistsForField.monthlyPayingUsers && <NumberField source='monthlyPayingUsers' label='Monthly paying&nbsp;users' />}
        {dataExistsForField.revenueIncludingTax &&
        <NumberField source='revenueIncludingTax' options={moneyFormatOptions} label='Total gross&nbsp;PV' />
        }
        {dataExistsForField.tax && <NumberField source='tax' options={moneyFormatOptions}label='Taxes' />}
        
        {dataExistsForField.netRevenueFee && 
          <NumberField source='netRevenueFee' options={moneyFormatOptions} label='Total PV after VAT+BHP+USO' />
        }
        {dataExistsForField.pgiFee && <NumberField source='pgiFee' label='Pgi Fee' options={moneyFormatOptions} />}
        {dataExistsForField.revenueExcludingTax &&
        <NumberField source='revenueExcludingTax' options={moneyFormatOptions}label={`${operator.toUpperCase()} Net Revenue`} />
        }

        {(dataExistsForField.total || dataExistsForField.revenueFee) && 
        <NumberField source={commissionField} options={moneyFormatOptions} label='TLX commission' />
        }
      </Datagrid>
    </div>
  )
}

