import { useCallback, useEffect } from 'react'

import { Operator, useOperatorContext } from '@services/operator'
import { Permissions } from '@services/permissions'

export const useActiveOperator = (permission: Permissions, defaultOperator?: Operator) => {
  const { setOperatorForView , getOperatorForView } = useOperatorContext()

  const setOperator = useCallback(
    (operator: Operator) => setOperatorForView(permission, operator),
    [setOperatorForView, permission]
  )

  const currentOperator = getOperatorForView(permission)
  const operator = getOperatorForView(permission) || defaultOperator

  useEffect(
    () => {
      if (currentOperator) return

      defaultOperator && setOperator(defaultOperator)
    }, 
    [currentOperator, defaultOperator, setOperator]
  )

  return {
    operator, 
    setOperator
  }
}
