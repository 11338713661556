import { ChangeEventHandler, MouseEventHandler, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import Select, { SelectProps } from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { createTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import { defaultTheme, useListContext } from 'react-admin'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import { Operator } from '@services/operator'

import { FilterListContext } from './CatalogFilters'

const useStyles = makeStyles({
  fileInput: {
    display: 'none',
  },
  settingsButton: {
    marginLeft: '10px'
  },
  fileButtons: {
    display: 'flex',
    width: '100%'
  },
  browseLabel: {
    flexGrow: 1
  },
  settings: {
    marginTop: 10
  },
  settingsRow: {
    marginBottom: 10
  },
  settingsLabel: {
    width: 100,
    display: 'inline-block'
  },
  smallSelect: {}
})

const themeOverrides = createTheme(defaultTheme, {
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '6px 26px 6px 6px',
        width: 100
      },
    },
  }
})

const defaultSettings: Record<Operator, { headerPosition: number, delimiter: string }> = {
  digi: {
    headerPosition: 3,
    delimiter: ';'
  },
  dtac: {
    headerPosition: 0,
    delimiter: ';'
  },
  indosat: {
    headerPosition: 0,
    delimiter: ';'
  },
  gp: {
    headerPosition: 0,
    delimiter: ';'
  },
  tri: {
    headerPosition: 0,
    delimiter: ';'
  }
}

export type FileUploadData = Partial<{ blob: Blob, headerPosition: number, delimiter: string }>|null

type FileUploadProps = {
  isDisabled: boolean
  data: [
    data: FileUploadData,
    setData: (fileUpload: FileUploadData) => void
  ]
}

export function FileUpload(props: FileUploadProps) {
  const classes = useStyles()
  const { filterValues } = useListContext() as FilterListContext
  const defaults = defaultSettings[filterValues.operator]

  const [data, setData] = props.data
  const [headerPosition, setHeaderPosition] = useState(defaults.headerPosition)
  const [delimiter, setDelimiter] = useState(defaults.delimiter)

  const [fileInputValue, setFileInputValue] = useState('')
  const [settingsAnchorElement, setSettingsAnchorElement] = useState<HTMLButtonElement|null>(null)

  const fileBrowserHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files?.item(0)
    if (!file) return

    setData({ 
      blob: file, 
      headerPosition, 
      delimiter 
    })
  }

  const browseHandler: MouseEventHandler<HTMLLabelElement> = event => {
    if (props.isDisabled) {
      event.preventDefault()
    }
  }

  const openSettingsHandler: MouseEventHandler<HTMLButtonElement> = event => {
    setSettingsAnchorElement(event.currentTarget)
  }

  const closeSettingsHandler = () => setSettingsAnchorElement(null)

  function clearFileHandler() {
    setData(null)
    setFileInputValue('')
    setHeaderPosition(defaults.headerPosition)
    setDelimiter(defaults.delimiter)
  }

  const changeDelimiterHandler: SelectProps['onChange'] = event => {
    setDelimiter(event.target.value as string)
    setData({
      ...data, 
      delimiter: event.target.value as string
    })
  }

  const changeHeaderPositionHandler: SelectProps['onChange'] = event => {
    setHeaderPosition(event.target.value as number)
    setData({
      ...data, 
      headerPosition: event.target.value as number
    })
  }

  return (
    <ThemeProvider theme={themeOverrides}>
      <input
        accept='text/csv'
        className={classes.fileInput}
        id='file'
        type='file'
        value={fileInputValue}
        onChange={fileBrowserHandler}
      />
      <div className={classes.fileButtons}>
        <label htmlFor='file' className={classes.browseLabel} onClick={browseHandler}>
          <Button 
            disabled={props.isDisabled} 
            variant='outlined' 
            color='primary' 
            fullWidth={true} 
            component='span'
          >
            { data?.blob ? data.blob.name : 'Browse' }
          </Button>
        </label>
        <Button 
          className={classes.settingsButton} 
          variant='outlined' 
          onClick={openSettingsHandler}
        >
          <SettingsIcon />
        </Button>
        <Button onClick={clearFileHandler}>Clear</Button>
      </div>
      <Menu
        keepMounted
        open={!!settingsAnchorElement}
        anchorEl={settingsAnchorElement}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={closeSettingsHandler}>
        <MenuItem>
          <Typography className={classes.settingsLabel} variant='body1'>Header line:</Typography>
          <Select
            value={headerPosition}
            onChange={changeHeaderPositionHandler}
            variant='outlined'
            className={classes.smallSelect}>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </MenuItem>
        <MenuItem>
          <Typography className={classes.settingsLabel} variant='body1'>Delimiter:</Typography>
          <Select
            value={delimiter}
            onChange={changeDelimiterHandler}
            variant='outlined'
            className={classes.smallSelect}>
            <MenuItem value=';'>semicolon</MenuItem>
            <MenuItem value=':'>colon</MenuItem>
            <MenuItem value={' '}>space</MenuItem>
          </Select>
        </MenuItem>
      </Menu>
    </ThemeProvider>
  )
}
