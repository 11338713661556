import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { getUnseen, markSeen } from '@services/changelog'

import ChangeLogCard from '@components/ChangeLogCard'

const useStyles = makeStyles({
  cards: {
    marginTop: 30,
    position: 'relative'
  },
  header: {
    marginBottom: 5
  }
})

const ChangeLogComponent = () => {
  const classes = useStyles()
  const [items, setItems] = useState(getUnseen())

  const close = (date: string) => {
    markSeen(date)
    setItems(items.filter(item => item.date !== date))
  }

  return (
    <div className={classes.cards}>
      { items.length ? <Typography variant='h5' className={classes.header}>What's new</Typography> : '' }
      { items.map((item, key) => <ChangeLogCard {...item } key={key} onClick={ close.bind(null, item.date) } />) }
    </div>
  )
}

export default ChangeLogComponent
