import { Fragment, MouseEventHandler, useEffect, useState} from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import FormControlLabel from '@material-ui/core/FormControlLabel'

type TableFilterProps<T = any> = {
  values: T[]
  onChange: (truthyFilters: string[]) => void
}

const TableFilter = ({ values, onChange }: TableFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null)
  const [state, setState] = useState<Record<string, boolean>>(Object.assign({}, ...values.map(value => ({[value]: true}))))

  useEffect(() => {
    const truthyFilters = Object.entries(state)
      .filter(([, value]) => value)
      .map(([name]) => name)

    onChange(truthyFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleChange: CheckboxProps['onChange'] = event => setState({
    ...state,
    [event.target.name]: event.target.checked
  })

  if (values.length < 2) return null

  return (
    <Fragment>
      <IconButton size='small' onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {Object.entries(state).map(([label], index) =>
          <MenuItem key={index}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={state[label]} 
                  onChange={handleChange} 
                  name={label} />
              }
              label={label}
            />
          </MenuItem>
        )}
      </Menu>
    </Fragment>

  )
}

export default TableFilter
