import { AccessToken, OktaAuth } from '@okta/okta-auth-js'

import config from '@services/config'

export const TOKEN_ACCESS = 'accessToken'
export const TOKEN_ID = 'idToken'

export type GroupType = 'SUPER_ADMIN' | 'DIGI' | 'DTAC' | 'INDOSAT' | 'TRI' | 'GP'

export const authClient = new OktaAuth(config.okta)

export async function getUserGroups(): Promise<GroupType[]> {
  try {
    const { accessToken } = await authClient.tokenManager.get(TOKEN_ACCESS) as AccessToken
    const { payload } = authClient.token.decode(accessToken)
    if (!payload.groups) return []

    return (payload.groups as GroupType[]).filter((group: string): boolean => group.toLowerCase() !== 'everyone')
  } catch {
    return []
  }
}

