import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { format, differenceInDays, isSameDay, isWithinInterval } from 'date-fns'
import clsx from 'clsx'

import { MAX_SELECTABLE_DAYS } from '@components/RangeDateInput'

type CalendarDayComponentProps = {
  date: Date|null,
  hoverDay: Date|null,
  onMouseEnter: () => void,
  selected: { first: Date|null, last: Date|null },
  classes: Record<string, string>,
  dayInCurrentMonth: number,
  onClick: () => void
}

// TODO refactor Calendar as we pass a lot of nulls here without checking
const CalendarDayComponent = ({
  date,
  hoverDay,
  onMouseEnter: parentHoverHandler,
  selected: { first, last },
  classes, 
  dayInCurrentMonth,
  onClick: parentClickHandler
}: CalendarDayComponentProps) => {

  const [ tooltipOpen, setTooltipOpen ] = useState(false)

  const isFirstDay = isSameDay(date!, first!)
  const isLastDay = isSameDay(date!, last!)
  const isHover = isSameDay(date!, hoverDay!)
  const hoverDiff = differenceInDays(hoverDay!, first!)

  function toolTipOnOpen() {
    const diff = differenceInDays(date!, first!)
    if(diff >= MAX_SELECTABLE_DAYS) {
      setTooltipOpen(true)
    }
  }

  function toolTipOnClose() {
    setTooltipOpen(false)
  }

  let wrapperClassName = clsx(classes.dayWrapper)
  let dayClassName = clsx(classes.day, {
    [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    [classes.daySelected]: isFirstDay
  })

  if(first && !last && hoverDay && hoverDiff > 0 && hoverDiff < MAX_SELECTABLE_DAYS ) {
    const isBetween = isWithinInterval(date!, { start: first, end: hoverDay })

    wrapperClassName = clsx(wrapperClassName, {
      [classes.wrapperSelected]: isFirstDay,
      [classes.hoverBetween]: !isFirstDay && isBetween,
      [classes.hoverEnd]: !isFirstDay && isHover
    })
  }

  if(first && last) {
    const isBetween = isWithinInterval(date!, { start: first, end: last })

    wrapperClassName = clsx(wrapperClassName, {
      [classes.wrapperInterval]: isBetween || isFirstDay,
      [classes.wrapperSelectedFirst]: isFirstDay,
      [classes.wrapperSelectedLast]: isLastDay
    })

    dayClassName = clsx(dayClassName, {
      [classes.daySelected]: isLastDay,
    })
  }

  return (
    <div
      className={wrapperClassName}
      onMouseEnter={parentHoverHandler}
      onClick={parentClickHandler}>
      <Tooltip
        open={tooltipOpen}
        onOpen={toolTipOnOpen}
        onClose={toolTipOnClose}
        title='You cannot select more than 7 days'>
        <IconButton className={dayClassName}>
          <span> {format(date!, 'd')} </span>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default CalendarDayComponent
