import {
  Datagrid,
  TextField,
  NumberField,
  useListContext,
  DatagridProps,
} from 'react-admin'
import { Record, RecordMap } from 'ra-core'

import { Operator } from '@services/operator'

type OperatorFilterProps = { filterValues?: { operator: Operator } }
type SettlementReportDatagridProps = DatagridProps & OperatorFilterProps

export const checkEmptyDataForFields = (fields: string[], data: RecordMap<Record>) => Object.fromEntries(
  fields.map(field => [field, Object.values(data).filter(row => row[field]).length])
)

export const SettlementReportDatagrid = (props: SettlementReportDatagridProps) => {
  const { data } = useListContext()

  const { filterValues: { operator } } = props as DatagridProps & Required<OperatorFilterProps>
  const itemWithCurrency = Object.values(data).find(value => value.currency && value.currency !== 'Unknown')
  const currency = itemWithCurrency ? itemWithCurrency.currency : null
  const feePerUserOptions = currency && { style: 'currency', currency, maximumFractionDigits: 4 }

  const dataExistsForField = checkEmptyDataForFields(
    [
      'feePerUser', 
      'total',
      'revenueIncludingTax',
      'revenueExcludingTax',
      'revenueFee',
      'tax',
      'pgiFee',
      'netRevenueFee',
      'monthlyPayingUsers'
    ],
    data
  )

  const commissionField = 'revenueFee'
  const moneyFormatOptions = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  const rowStyle = ({ isSummaryRow, isGrandTotal }: Record, _idx: number) => {
    const backgroundColor = isSummaryRow
      ? '#DCDCDC'
      : isGrandTotal ? '#0F284C' : undefined
    
    const color = isGrandTotal ? 'white' : 'inherit'

    return {
      backgroundColor,
      color,
    }
  }

  return (
    <div>
      <Datagrid {...props} rowStyle={rowStyle} key={Date.now()}>
        <TextField source='channel' />
        <NumberField source='fundsource' label='Fund source' />
        <NumberField source='totalTransactions' label='Successful transactions' />
        <NumberField source='monthlyPayingUsers' label='Monthly paying&nbsp;users' />
        {dataExistsForField.revenueIncludingTax &&
        <NumberField source='revenueIncludingTax' options={moneyFormatOptions}label='Total gross&nbsp;PV' />
        }
        {dataExistsForField.tax && <NumberField source='tax' label='Taxes' options={moneyFormatOptions} />}
        {dataExistsForField.netRevenueFee && 
          <NumberField source='netRevenueFee' options={moneyFormatOptions} label='Total PV after VAT+BHP+USO' />
        }

        {dataExistsForField.pgiFee && <NumberField source='pgiFee' label='Pgi Fee' options={moneyFormatOptions} />}

        {dataExistsForField.revenueExcludingTax &&
        <NumberField source='revenueExcludingTax' options={moneyFormatOptions} label={`${operator.toUpperCase()} Net Revenue`} />
        }

        {dataExistsForField.feePerUser && <NumberField source='feePerUser' options={{ ...feePerUserOptions }} label='Fee per user' />}
        {(dataExistsForField.total || dataExistsForField.revenueFee) && 
        <NumberField source={commissionField} options={moneyFormatOptions} label='TLX commission' />
        }
      </Datagrid>
    </div>
  )
}
