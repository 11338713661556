import { useInput, InputProps } from 'react-admin'
import { MuiPickersUtilsProvider, DatePicker, DatePickerProps } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles({
  picker: {
    marginBottom: 8
  }
})

export function formatDate(input: MaterialUiPickersDate|number) {
  const date = input instanceof Date ? input : new Date(input!)
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')

  return year + '-' + month
}

type DateInputProps = InputProps<Omit<DatePickerProps, 'value'|'onChange'>>

export const DateInput = ({ label, options, ...props }: DateInputProps) => {
  const { input } = useInput(props)
  const classes = useStyles()

  const onChange: BasePickerProps['onChange'] = value => {
    const date = formatDate(value)
    input.onChange(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        { ...options }
        label={label}
        value={input.value}
        onChange={onChange}
        className={classes.picker}
      />
    </MuiPickersUtilsProvider>
  )
}
