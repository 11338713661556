import { AuthProvider, UserIdentity } from 'react-admin'

import { authClient, TOKEN_ID, TOKEN_ACCESS } from '@services/okta'
import { getPermissions } from '@services/permissions'

const authService: AuthProvider = {
  // called when the user attempts to log in
  login: () => {
    return authClient.token.getWithPopup({scopes: ['openid', 'profile']}).then(({ tokens }) => {
      authClient.tokenManager.add(TOKEN_ACCESS, tokens.accessToken!)
      authClient.tokenManager.add(TOKEN_ID, tokens.idToken!)
    })
  },
  // called when the user clicks on the logout button
  logout: () => {
    authClient.tokenManager.clear()
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }: { status: number }) => {
    if (status === 401 || status === 403) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return authClient.tokenManager.get(TOKEN_ACCESS)
      .then(accessToken => {
        if(!accessToken) {
          return Promise.reject()
        }

        const expired = authClient.tokenManager.hasExpired(accessToken)
        return expired ? Promise.reject() : Promise.resolve()
      })
  },
  redirectToLogin: () => {
    window.location.reload()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions,
  getIdentity: () => authClient
    .token
    .getUserInfo()
    .then(user => ({
      id: user.email,
      fullName: user.name,
      avatar: null
    } as any as UserIdentity))
    .catch(() => {
      return { } as any as UserIdentity
    })
}

export default authService
