import { call, put, takeLeading } from 'redux-saga/effects'

import { getCatalogVersions, getOperatorCatalogs } from '@services/catalog'
import { Operator } from '@services/operator'

import {
  type RequestVersionActionPayload,
  type RequestCatalogActionPayload,
  REQUEST_VERSIONS,
  receiveVersions,
  versionsError,
  REQUEST_CATALOGS,
  receiveCatalogs,
  catalogsError, 
} from '@actions/catalog'

export function* fetchVersions({ operator, catalog }: { operator: Operator, catalog: string }) {
  try {
    const response: {} = yield call(getCatalogVersions, operator, catalog)
    yield put(receiveVersions(response))
  } catch(error) {
    yield put(versionsError(error as Error))
  }
}

export function* fetchCatalogs({ operator }: { operator: Operator}) {
  try {
    const response: {} = yield call(getOperatorCatalogs, operator)
    yield put(receiveCatalogs(response))
  } catch(error) {
    yield put(catalogsError(error as Error))
  }
}

export default function* root() {
  yield takeLeading<RequestVersionActionPayload>(REQUEST_VERSIONS, fetchVersions)
  yield takeLeading<RequestCatalogActionPayload>(REQUEST_CATALOGS, fetchCatalogs)
}
