import {
  ListProps,
} from 'react-admin'

import { SettlementReportList } from './SettlementReportList'
import MissingCallbacksList from './MissingCallbacksList'

export const SettlementReport = (props: ListProps) => {
  return (
    <>
      <SettlementReportList {...props} />
      <MissingCallbacksList />
    </>
  )
}
