import React from 'react'
import { Notification, useLogin } from 'react-admin'
import { Redirect } from 'react-router-dom'
import { Button, CircularProgress, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinnerPlaceholder: {
    height: 100,
    marginBottom: 20
  },
  errorPlaceholder: {
    height: 20,
    marginTop: 20,
    fontSize: '0.9em'
  },
  spinner: {
    color: '#b3b3b3'
  }
})

export const LoginComponent = () => {
  const [inProgress, setProgress] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [loginSuccessful, setLoginSuccessful] = React.useState(false)
  const classes = useStyles()
  const login = useLogin()

  const loginHandler = () => {
    setProgress(true)
    setHasError(false)

    return login({})
      .then(() => {
        setProgress(false)
        setHasError(false)
        setLoginSuccessful(true)
      })
      .catch(err => {
        setHasError(true)
        setProgress(false)
        console.error(err)
      })
  }

  return (
    <div>
      <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' style={{minHeight: '100vh'}}>
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <div className={classes.container}>
            <div className={classes.spinnerPlaceholder}>
              { inProgress ? <CircularProgress
                size={100}
                thickness={1}
                className={classes.spinner} /> : '' }
            </div>
            <Button variant='contained' onClick={loginHandler}>
              <span>Login with Okta</span>
            </Button>
            <Box color='error.main' className={classes.errorPlaceholder}>
              { hasError ? <span>There was an error. Try enabling popups</span> : '' }
            </Box>
          </div>
        </Box>
      </Grid>
      <Notification />
      { loginSuccessful ? <Redirect to='/'/> : '' }
    </div>
  )
}
