import http from '@services/http'
import config from '@services/config'

import { Operator } from './operator'

type CampaignAction = {
  type: string,
  rule: Record<string,string>
  actions: Record<string,string>,
}

type Campaign = {
    operator: string,
    applications: string[],
    accountTypes: string[],
    name: string,
    trigger: { type: string },
    actions: CampaignAction[],
    flows: string[]
    active: boolean,
    priority: number,
    abTest: boolean,
    description: string,
    id: string,
    changed: string
}

export type getCampaignListResponse = [

]

export async function getCampaignList(operator: Operator) {
  const url = `${config.api.url}/campaigns/v1/${operator}/list`

  return http.get<Campaign>(url)
}

export type Metric = {
  description: string
  name: string
  value: string
}

export type CampaignMetricsResponse = {
  arpu: Metric,
  currency: Metric,
  day: Metric,
  offerCount: Metric,
  purchaseCount: Metric,
  revenue: Metric,
  visitCr: Metric
}

export async function getCampaignMetrics(operator: Operator) {
  const url = `${config.api.url}/stats/v1/${operator}/metrics/overall`

  return http.get<CampaignMetricsResponse>(url)
}
