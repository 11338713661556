import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import { GetListResult, SortPayload, Record as RecordAdminType } from 'react-admin'
import type { Dictionary } from 'lodash'

import { emptyResponse, sortByField } from '@providers/data-provider'

import http from '@services/http'
import config from '@services/config'

import { Operator } from './operator'

interface OperatorCatalog {
  uniqueIdentifier: string,
}

interface NotifyOperatorCatalog extends OperatorCatalog {
   planId: string
   name: string
   quotaUsage: string
}

interface DataOperatorCatalog extends OperatorCatalog {
  planId: string
  defaultLanguage: string
  planName: Record<string, string>,
  description: Record<string,string>
  dataplanTypes: string[]
  quotaBytes: number
  channels?: string[]
  cost: number
  currency: string
  duration: number
  bucketTypes?: string[]
  planGroup: string
  accountType: string
  baseplanRequirement: string[]
  timeWindow?: {
    name: string
    start: string
    end: string
    days: string[]
    }
}

type OperatorMapper = <T extends OperatorCatalog = OperatorCatalog>(item: T, index: number) => Dictionary<any>

/* TODO 
  Rewrite the mapping and provide correct types for each catalog response
  Types should be retrieved by http.get<ResponseType> 
  use strong typing with a hint like mapper('digi') to assert payload type
*/
const mapByOperator: Record<Operator, OperatorMapper| Record<string, OperatorMapper>> = {
  gp: gpMapper,
  digi: digiMapper,
  dtac: dtacMapper,
  tri: triDefaultMapper,
  indosat: {
    default: indosatDefaultMapper,
    notify: indosatNotifyMapper
  }
}

const apiPath = 'catalogs/v2'

type OperatorCatalogResponse = string[]

export async function getOperatorCatalogs(operator: Operator) {
  if(!operator) {
    return emptyResponse()
  }

  const url = `${config.api.url}/${apiPath}/${operator}`

  const response = await http.get<OperatorCatalogResponse>(url)
  return operatorCatalogsMapper(response)
}

type GetCatalogOpts = {
  filter: { operator: Operator, catalog: string }
  pagination: { page: number, perPage: number },
  sort: SortPayload
}

interface DigiOperatorCatalog extends DataOperatorCatalog {
  resetPeriod: number,
  presedence: number,
  customerAttribute: string
  roamingPlan: boolean
}

interface DtacOperatorCatalog extends DataOperatorCatalog {}

interface IndosatOperatorCatalog extends DataOperatorCatalog {
  offerSystem: string
  shortCode: string,
}

interface GpOperatorCatalog extends DataOperatorCatalog {}

interface TriOperatorCatalog extends IndosatOperatorCatalog {}

type CatalogResponse = OperatorCatalog[]

export async function getCatalog(params: GetCatalogOpts): Promise<GetListResult<RecordAdminType>> {
  const {
    filter: { operator, catalog },
    pagination: { page, perPage }
  } = params

  if(!operator || !catalog) {
    return emptyResponse()
  }

  const url = `${config.api.url}/${apiPath}/${params.filter.operator}/${catalog}`

  const response = await http.get<CatalogResponse>(url)

  const operatorMapper = mapByOperator[operator]
  const responseMapper = typeof operatorMapper === 'function'
    ? operatorMapper
    : operatorMapper[catalog]

  const mapped = response.map(responseMapper)
  const sorted = mapped.sort(sortByField.bind(null, params.sort))
  const portion = sorted.slice((page - 1) * perPage, page * perPage) as any as RecordAdminType[]

  return {
    data: portion,
    total: mapped.length
  }
}

type UploadOpts = {
  operator: Operator,
  catalog: string
  
}

type UploadFileOpts = UploadOpts & {
  file: {
    blob: Blob,
    headerPosition: number,
    delimiter: string
  }
}

export function uploadFile(params: UploadFileOpts, isDryRun = false) {
  const { file, operator, catalog } = params
  const url = addDryRunParam([config.api.url, apiPath, operator, catalog, 'csv'].join('/'), isDryRun)

  const payload = new FormData()

  payload.append('file', file.blob)
  payload.append('header', `${file.headerPosition}`)
  payload.append('separator', file.delimiter)
  payload.append('operator', operator)

  return http.post(url, payload)
}

type UploadLinkOpts = UploadOpts & { link: string }

export function uploadLink(params: UploadLinkOpts, isDryRun = false) {
  const { link, operator, catalog } = params
  const url = addDryRunParam([config.api.url, apiPath, operator, catalog, 'gsheet'].join('/'), isDryRun)
  const id = getIdFromLink(link)

  return http.post(
    url,
    JSON.stringify({ sheetId: id }),
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
}

type CatalogVersions = {
  alias: string,
  aliasedIndexes: string[]
  versionedIndexes: string[]
}

export async function getCatalogVersions(operator: Operator, catalog: string) {
  const url = [config.api.url, apiPath, operator, catalog, 'catalog-index-report'].join('/')
  return http.get<CatalogVersions>(url).then(versionsMapper)
}

export function rollbackCatalog(operator: Operator, catalog: string) {
  const url = [config.api.url, apiPath, operator, catalog, 'rollback'].join('/')
  return http.post(url)
}

function digiMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as DigiOperatorCatalog

  return omitBy({
    id: index,
    bucketTypes: item.bucketTypes,
    customerAttribute: item.customerAttribute,
    dataplanTypes: item.dataplanTypes,
    defaultLanguage: item.defaultLanguage,
    planId: item.planId,
    planName: item.planName.en,
    presedence: item.presedence,
    quotaBytes: item.quotaBytes,
    resetPeriod: item.resetPeriod,
    roamingPlan: item.roamingPlan,
    timeWindow: timeWindowMapper(item.timeWindow),
    uniqueIdentifier: item.uniqueIdentifier,
  }, isUndefined)
}

function dtacMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as DtacOperatorCatalog

  return omitBy({
    id: index,
    accountType: item.accountType,
    bucketTypes: item.bucketTypes,
    cost: item.cost,
    currency: item.currency,
    dataplanTypes: item.dataplanTypes,
    defaultLanguage: item.defaultLanguage,
    description: item.description?.en,
    planId: item.planId,
    planName: item.planName.en,
    uniqueIdentifier: item.uniqueIdentifier,
  }, isUndefined)
}

function triDefaultMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as TriOperatorCatalog

  return omitBy({
    id: index,
    accountType: item.accountType,
    baseplanRequirement: item.baseplanRequirement,
    bucketTypes: item.bucketTypes,
    channels: item.channels?.map((channel: string) => ({ name: channel })),
    cost: item.cost,
    currency: item.currency,
    dataplanTypes: item.dataplanTypes,
    defaultLanguage: item.defaultLanguage,
    description: item.description?.id,
    duration: item.duration,
    offerSystem: item.offerSystem,
    planGroup: item.planGroup,
    planId: item.planId,
    planName: item.planName?.id,
    quotaBytes: item.quotaBytes,
    shortCode: item.shortCode,
    uniqueIdentifier: item.uniqueIdentifier
  }, isUndefined)
}

function gpMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as GpOperatorCatalog

  return omitBy({
    id: index,
    accountType: item.accountType,
    baseplanRequirement: item.baseplanRequirement,
    bucketTypes: item.bucketTypes,
    channels: item.channels?.map((channel: string) => ({ name: channel })),
    cost: item.cost,
    currency: item.currency,
    dataplanTypes: item.dataplanTypes,
    defaultLanguage: item.defaultLanguage,
    description: item.description?.id,
    duration: item.duration,
    planGroup: item.planGroup,
    planId: item.planId,
    planName: item.planName?.id,
    quotaBytes: item.quotaBytes,
    uniqueIdentifier: item.uniqueIdentifier
  }, isUndefined)
}

function indosatDefaultMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as IndosatOperatorCatalog

  return omitBy({
    id: index,
    accountType: item.accountType,
    baseplanRequirement: item.baseplanRequirement,
    bucketTypes: item.bucketTypes,
    channels: item.channels?.map((channel: string) => ({ name: channel })),
    cost: item.cost,
    currency: item.currency,
    dataplanTypes: item.dataplanTypes,
    defaultLanguage: item.defaultLanguage,
    description: item.description?.id,
    duration: item.duration,
    offerSystem: item.offerSystem,
    planGroup: item.planGroup,
    planId: item.planId,
    planName: item.planName?.id,
    quotaBytes: item.quotaBytes,
    shortCode: item.shortCode,
    uniqueIdentifier: item.uniqueIdentifier
  }, isUndefined)
}

function indosatNotifyMapper(operatorCatalog: OperatorCatalog, index: number) {
  const item = operatorCatalog as NotifyOperatorCatalog

  return omitBy({
    id: index,
    planId: item.uniqueIdentifier,
    planName: item.name,
    quotaUsage: item.quotaUsage
  }, isUndefined)
}

type versionsMapperOpts = {
  aliasedIndexes: string[]
  versionedIndexes: string[]
}

function versionsMapper(response: versionsMapperOpts) {
  const secondToLast = response.versionedIndexes.length - 2
  return {
    currentVersion: response.aliasedIndexes[0],
    previousVersion: response.versionedIndexes[secondToLast] || response.aliasedIndexes[0]
  }
}

type timeWindowMapperOpts = { start: string, end: string, name: 'daily' | 'daysofweek' | string, days: string[] }

function timeWindowMapper(data?: timeWindowMapperOpts): string|void {
  if (!data) return

  switch (data?.name) {
  case 'daily':
    return `from ${data.start} to ${data.end}`
  case 'daysofweek':
    return data.days.join(', ')
  default:
    return JSON.stringify(omit(data, ['name', 'timezone']))
  }
}

function operatorCatalogsMapper(catalogs: OperatorCatalogResponse): { operatorCatalogs: string[] } {
  return {
    operatorCatalogs: catalogs
  }
}

export function getIdFromLink(link: string): string|null|undefined {
  const regex = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/
  if (regex.test(link)) {
    return regex.exec(link)?.[1]
  }
}

function addDryRunParam(url: string, shouldAdd: boolean): string {
  const query = new URLSearchParams({ dryRun: 'true' })

  return shouldAdd 
    ? `${url}'?'${query.toString()}`
    : url
}
