import { Route } from 'react-router-dom'
import merge from 'lodash/merge'
import { Admin, RenderResourcesFunction, Resource, defaultTheme } from 'react-admin'
import { ReactElement } from 'react'

import { SettlementReport } from '@lists/settlement-report'
import PurchaseLookup from '@lists/purchase-lookup'
import CatalogList from '@lists/catalog'

import dataProvider from '@providers/data-provider'

import authProvider from '@services/auth'
import { authClient } from '@services/okta'
import * as permissionsService from '@services/permissions'
import { OperatorProvider } from '@services/operator'

import catalogReducer from '@reducers/catalog'
import campaignsReducer from '@reducers/campaigns'

import catalogSaga from '@sagas/catalog'
import campaignsSaga from '@sagas/campaigns'

import { LoginComponent } from '@components/login/Login'
import HomeComponent from '@components/home/Home'
import MainLayout from '@components/Layout'
import DebugComponent from '@components/Debug'
import CampaignComponent from '@components/campaign/Campaign'

const routes = [
  <Route exact path='/' component={HomeComponent} />,
  <Route exact path='/campaigns' component={CampaignComponent} />,
]

const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#0F284C'
    }
  },

  overrides: {
    MuiTableCell: {
      body: {
        color: 'inherit'
      }
    }
  }
})

const getResources: RenderResourcesFunction = (permissions) => {
  const canViewSettlementReport = permissionsService.hasAny(permissionsService.Permissions.VIEW_SETTLEMENT_REPORT, permissions)
  const canViewPurchaseReport = permissionsService.hasAny(permissionsService.Permissions.VIEW_PURCHASE_LOOKUP, permissions)
  const canViewCatalog = permissionsService.hasAny(permissionsService.Permissions.VIEW_CATALOG, permissions)

  return [
    canViewSettlementReport && <Resource intent='registration' name='missingCallbacks' options={{ showMenu: false }} />,
    canViewSettlementReport && <Resource name='summary' options={{label: 'Settlement report'}} list={SettlementReport}/>,
    canViewPurchaseReport && <Resource name='purchase-lookup' options={{label: 'Transaction lookup'}} list={PurchaseLookup}/>,
    canViewCatalog && <Resource name='catalog' options={{label: 'Catalog'}} list={CatalogList}/>,
    <DebugComponent />
  ].filter(Boolean) as ReactElement[]
}

const App = () => {
  authClient.tokenManager.on('removed', authProvider.redirectToLogin)

  return (
    <OperatorProvider>
      <Admin
        disableTelemetry
        theme={theme}
        layout={MainLayout}
        customRoutes={routes}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginComponent}
        customReducers={{
          catalog: catalogReducer,
          campaigns: campaignsReducer
        }}
        customSagas={[
          catalogSaga,
          campaignsSaga
        ]}>
        { getResources }
      </Admin>
    </OperatorProvider>
  )
}

export default App
