import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker'
import { CSSProperties } from 'react'

const useStyles = makeStyles(theme => ({
  toolbar: {
    background: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    padding: [[10, 0]] as unknown as CSSProperties['padding'],
  },
  year: {
    fontSize: 32,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  }
}))

type DateInputToolbarProps = ToolbarComponentProps

export default function DateInputToolbar({ date, setOpenView }: DateInputToolbarProps) {
  const classes = useStyles()

  return (
    <div className={classes.toolbar}>
      <Button 
        className={classes.year} 
        onClick={() => { setOpenView('year') }}>
        { date?.getFullYear() }
      </Button>
    </div>
  )
};
