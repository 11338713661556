import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  DateField,
  BooleanField,
  TextInput,
  SelectInput,
  FilterProps,
  ListProps,
  SelectInputProps} from 'react-admin'

import { Operator, getListForSelect } from '@services/operator'
import { Permissions } from '@services/permissions'

import RangeDateInput, { defaultStartDate, defaultEndDate, formatDate } from '@components/RangeDateInput'

import { useViewOperator } from '@hooks/useViewOperator'

const PurchaseLookupFilters = (props: Omit<FilterProps, 'children'>) => {
  const { operator, setOperator, viewOperators } = useViewOperator(Permissions.VIEW_PURCHASE_LOOKUP)

  const handleOperatorChange: SelectInputProps['onChange'] = event => {
    setOperator(event?.target.value as Operator)
  }

  return (
    <Filter {...props}>
      <TextInput
        source='id'
        alwaysOn={true}
        allowEmpty={false}
        label='ID'
        resettable />
      <SelectInput
        source='operator'
        alwaysOn={true}
        allowEmpty={false}
        value={operator}
        onChange={handleOperatorChange}
        choices={getListForSelect(viewOperators)}
      />
      <RangeDateInput alwaysOn={true} source='dateRange' />
    </Filter>
  )
}

const PurchaseLookup = (props: ListProps) => {
  const { operator } = useViewOperator(Permissions.VIEW_PURCHASE_LOOKUP)
  const rangeInputDefaultValue = formatDate(defaultStartDate) + '_' + formatDate(defaultEndDate)

  return (
    <List {...props}
      title='Transaction lookup'
      filters={<PurchaseLookupFilters/>}
      filterDefaultValues={{
        operator: operator?.toLowerCase(),
        dateRange: rangeInputDefaultValue
      }}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source='appId' label='Channel'/>
        <NumberField source='cost'/>
        <TextField source='currency'/>
        <TextField source='operator'/>
        <TextField source='planName'/>
        <DateField source='sendAt' label='Purchase date' showTime options={{showTime: true}}/>
        <BooleanField source='status'/>
      </Datagrid>
    </List>
  )
}

export default PurchaseLookup
