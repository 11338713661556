import { useEffect, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { Permissions } from '@services/permissions'

import { requestMetricsOverall } from '@actions/campaigns'

import { campaignsSelector } from '@selectors/campaignSelector'
import { adminUISelector } from '@selectors/reactAdminSelector'

import CampaignMetricItem from '@components/campaign/CampaignMetricItem'

import { useViewOperator } from '@hooks/useViewOperator'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: 30
  },
  metrics: {
    display: 'flex',
    padding: [[theme.spacing(2), 0]] as unknown as CSSProperties['padding']
  }
}))

const CampaignMetrics = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { campaignMetrics } = useSelector(campaignsSelector)
  const { viewVersion } = useSelector(adminUISelector)
  const { operator } = useViewOperator(Permissions.VIEW_CAMPAIGN)

  useEffect(() => {
    if(operator) {
      dispatch(requestMetricsOverall(operator))
    }
  }, [operator, dispatch, viewVersion])

  return (
    <Paper className={classes.container}>
      <div>
        <Typography variant='h6'>Campaigns overview</Typography>
      </div>
      <div className={classes.metrics}>
        <CampaignMetricItem {...campaignMetrics.offerCount} />
        <CampaignMetricItem {...campaignMetrics.purchaseCount} />
        <CampaignMetricItem {...campaignMetrics.revenue} currency={campaignMetrics.currency.value} />
        <CampaignMetricItem {...campaignMetrics.arpu} currency={campaignMetrics.currency.value} fractionDigits={2} />
        <CampaignMetricItem {...campaignMetrics.visitCr} />
      </div>
    </Paper>
  )
}

export default CampaignMetrics
