import { ReactNode } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  NumberField, 
  ListProps,
  PaginationProps,
  DatagridProps
} from 'react-admin'

import { Operator } from '@services/operator'
import { Permissions } from '@services/permissions'

import CatalogTopToolbar from '@components/catalog/CatalogTopToolbar'
import { QuotaField } from '@components/fields/QuotaField'
import { CatalogRowPanel } from '@components/catalog/CatalogRowPanel'
import { ChannelsField } from '@components/fields/ChannelsField'
import CatalogFilters from '@components/catalog/CatalogFilters'

import { useViewOperator } from '@hooks/useViewOperator'

type CatalogFilterProps = { filterValues: { operator: Operator, catalog: string } } & ListProps

const CatalogDatagrid = (props: DatagridProps) => {
  const { filterValues: { operator, catalog }} = props as CatalogFilterProps

  const operatorBasedFields: Record<Operator, ReactNode[] | Record<string, ReactNode>> = {
    gp: [],
    digi: [
      <QuotaField key='digi-0' source='quotaBytes' binary={true}/>
    ],
    dtac: [
      <NumberField key='dtac-0' source='cost' options={{style: 'currency', currency: 'THB', maximumFractionDigits: 0}}/>,
      <TextField key='dtac-1' source='accountType'/>
    ],
    indosat: {
      default: [
        <NumberField key='indosat-0' source='cost' options={{style: 'currency', currency: 'IDR', maximumFractionDigits: 0}}/>,
        <QuotaField key='indosat-1' source='quotaBytes' binary={true} />,
        <TextField key='indosat-2' source='accountType'/>,
        <ChannelsField key='indosat-3' source='channels' sortable={false} />,
      ],
      notify: []
    },
    tri: [
      <NumberField key='tri-0' source='cost' options={{style: 'currency', currency: 'IDR', maximumFractionDigits: 0}}/>,
      <QuotaField key='tri-1' source='quotaBytes' binary={true} />,
      <TextField key='tri-2' source='accountType'/>,
      <ChannelsField key='tri-3' source='channels' sortable={false} />,
    ]
  }

  const operatorFields = operatorBasedFields[operator]
  const dataGridFields = operatorFields
    ? Array.isArray(operatorFields) ? operatorFields : operatorFields[catalog]
    : []

  return (
    <Datagrid 
      expand={<CatalogRowPanel />} 
      {...props}
      rowClick='expand'
    >
      <NumberField source='planId' label='ID' />
      <TextField source='planName' label='Name' />
      {dataGridFields}
    </Datagrid>
  )
}

const CatalogPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const CatalogList = (props: ListProps) => {
  const { operator } = useViewOperator(Permissions.VIEW_CATALOG)

  return (
    <List {...props}
      title='Catalog'
      filters={<CatalogFilters/>}
      filterDefaultValues={{
        operator: operator?.toLowerCase(),
      }}
      bulkActionButtons={false}
      actions={<CatalogTopToolbar />}
      perPage={100}
      pagination={<CatalogPagination />}>
      <CatalogDatagrid />
    </List>
  )
}

export default CatalogList
