import { Layout, LayoutProps } from 'react-admin'

import AppBar from './AppBar'
import Menu from './Menu'

const MainLayout = (props: LayoutProps) => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
  />
)

export default MainLayout
