import {
  List,
  Filter,
  SelectInput,
  FilterProps,
  ListProps,
  SelectInputProps
} from 'react-admin'
import { addMonths, startOfMonth, endOfMonth } from 'date-fns'

import { getListForSelect, Operator } from '@services/operator'
import { Permissions } from '@services/permissions'

import DateInputToolbar from '@components/DateInputToolbar'
import { DateInput, formatDate } from '@components/DateInput'
import SettlementReportTopToolbar from '@components/SettlementReportTopToolbar'

import { useViewOperator } from '@hooks/useViewOperator'

import { SettlementReportDatagrid } from './SettlementGrid'

const SettlementReportFilter = (props: Omit<FilterProps, 'children'>) => {
  const today = new Date()
  const minDate = startOfMonth(addMonths(today, -12))
  const maxDate = endOfMonth(addMonths(today, -1))
  const { operator, setOperator, viewOperators } = useViewOperator(Permissions.VIEW_SETTLEMENT_REPORT)

  const handleOperatorChange: SelectInputProps['onChange'] = event => {
    setOperator(event.target.value as Operator)
  }

  return (
    <Filter {...props}>
      <DateInput
        source='reportMonth'
        alwaysOn={true}
        label='Month'
        options={{
          autoOk: true,
          minDate,
          maxDate,
          views: ['month'],
          format: 'MMMM',
          disableFuture: true,
          ToolbarComponent: DateInputToolbar
        }}
      />
      {operator && (
        <SelectInput
          source='operator'
          alwaysOn={true}
          allowEmpty={false}
          onChange={handleOperatorChange}
          value={operator}
          choices={getListForSelect(viewOperators)}
        />
      )}
    </Filter>
  )
}

const lastMonthDate = new Date().setMonth(new Date().getMonth() - 1)
const defaultDate = formatDate(lastMonthDate)

export const SettlementReportList = (props: ListProps) => {
  const { operator } = useViewOperator(Permissions.VIEW_SETTLEMENT_REPORT)

  return (
    <List {...props}
      title='Settlement report'
      filters={<SettlementReportFilter />}
      filterDefaultValues={{
        reportMonth: defaultDate,
        operator: operator?.toLowerCase()
      }}
      sort={{ field: 'channel', order: 'ASC' }}
      actions={<SettlementReportTopToolbar />}
      bulkActionButtons={false}
      pagination={false}
    >
      <SettlementReportDatagrid />
    </List>
  )
}
