import { useEffect, useState } from 'react'
import { ListControllerProps, useListContext } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import Select, { SelectProps } from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'

import { Operator } from '@services/operator'
import { Permissions } from '@services/permissions'

import { requestCatalogs } from '@actions/catalog'

import { catalogSelector } from '@selectors/catalogSelector'

import { useViewOperator } from '@hooks/useViewOperator'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    minHeight: 80,
    marginTop: -21,
    gap: 10
  },
})

export interface FilterListContext extends ListControllerProps {
  filterValues: {
    operator: Operator
    catalog: string
  }
}

export default function CatalogFilters() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { operator, setOperator, viewOperators } = useViewOperator(Permissions.VIEW_CATALOG)

  const [catalogValue, setCatalogValue] = useState<string>('default')
  const {
    filterValues: { operator: operatorFilter, catalog: catalogFilter },
    filterValues,
    setFilters
  } = useListContext() as FilterListContext

  const { operatorCatalogs, inProgress } = useSelector(catalogSelector)

  const changeOperator = (operator: Operator) => {
    setCatalogValue('default')
    setOperator(operator)
    dispatch(requestCatalogs(operator))
    setFilters({operator, catalog: 'default'}, {})
  }

  const changeCatalog = (catalog: string) => {
    setCatalogValue(catalog)
    setFilters({ ...filterValues, catalog }, {})
  }

  const handleOperatorChange: SelectProps['onChange'] = event => {
    changeOperator(event.target.value as Operator)
  }

  const handleCatalogChange: SelectProps['onChange'] = event => {
    changeCatalog(event.target.value as string)
  }

  useEffect(() => {
    if(operatorFilter) {
      changeOperator(operatorFilter)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorFilter])

  useEffect(() => {
    if (catalogFilter) {
      changeCatalog(catalogFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogFilter])

  return (
    <div className={classes.filters}>
      <FormControl variant='outlined' size='small' style={{ minWidth: 120}}>
        <InputLabel id='operator-selector-label'>Operator</InputLabel>
        {operator && (<Select
          labelId='operator-selector-label' 
          label='Operator'
          disabled={inProgress} 
          value={operator} 
          onChange={handleOperatorChange}
        >
          {viewOperators.map((operator, index) =>
            <MenuItem key={`${operator}-${index}`} value={operator}>{operator.toUpperCase()}</MenuItem>
          )}
        </Select>
        )}
      </FormControl>
      <FormControl variant='outlined' size='small' style={{ minWidth: 120}}>
        <InputLabel id='catalog-selector-label'>Catalog</InputLabel>
        <Select 
          labelId='catalog-selector-label' 
          label='Catalog' 
          disabled={inProgress} 
          value={catalogValue} 
          onChange={handleCatalogChange}
        >
          {operatorCatalogs.map((catalog, index) =>
            <MenuItem key={index} value={catalog}>{catalog}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}
