import { ChangeEventHandler, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { getIdFromLink } from '@services/catalog'

const useStyles = makeStyles({
  gsheetLink: {
    display: 'flex',
    marginTop: 15,
    height: 75
  },
  gsheetLinkClearButton: {
    height: 52
  },
})

type GSheetLinkProps = {
  isDisabled?: boolean
  data: [string, (value: string) => void]
}

export function GSheetLink(props: GSheetLinkProps) {
  const classes = useStyles()
  const [, setData] = props.data

  const [isGsheetLinkValid, setIsGsheetLinkValid] = useState(true)
  const [gsheetLinkInput, setGsheetLinkInput] = useState('')

  function handleClearGsheetLink() {
    setGsheetLinkInput('')
    setData('')
    setIsGsheetLinkValid(true)
  }

  const handleGsheetChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setGsheetLinkInput(value)
  }

  function handleGsheetBlur() {
    if(!gsheetLinkInput) return
    const hasId = getIdFromLink(gsheetLinkInput)

    if (hasId) setData(gsheetLinkInput)
    setIsGsheetLinkValid(!!hasId)
  }

  return (
    <div className={classes.gsheetLink}>
      <TextField
        error={!isGsheetLinkValid}
        helperText={!isGsheetLinkValid ? 'Invalid link' : ''}
        variant='outlined'
        margin='dense'
        id='gsheet-link'
        label='Google Sheet link'
        type='text'
        fullWidth
        value={gsheetLinkInput}
        onChange={handleGsheetChange}
        onBlur={handleGsheetBlur}
        disabled={ props.isDisabled }
      />
      <Button className={classes.gsheetLinkClearButton} onClick={handleClearGsheetLink}>Clear</Button>
    </div>
  )
}
