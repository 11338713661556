import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Authenticated, usePermissions } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import * as permissionService from '@services/permissions'

import { requestList } from '@actions/campaigns'

import CampaignTable, { Campaign as CampaignModel } from '@components/campaign/CampaignTable'
import CampaignMetrics from '@components/campaign/CampaignMetrics'
import CampaignOperatorSelector from '@components/campaign/CampaignOperatorSelector'

import { useViewOperator } from '@hooks/useViewOperator'

const useStyles = makeStyles({
  headerContainer: {
    marginBottom: 30
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  headerActions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  ongoingCampaigns: {
    marginBottom: 30
  },
  newCampaignButton: {
    marginLeft: 15
  }
})

type CampaignComponentProps = RouteComponentProps

type ReduxState = {
  campaigns: {
    campaignList: CampaignModel[]
  },
  admin: {
    ui: {
      viewVersion: number
    }
  }
}

const CampaignComponent = ({ location }: CampaignComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { campaignList } = useSelector((state: ReduxState) => state.campaigns)
  const { viewVersion } = useSelector((state: ReduxState) => state.admin.ui)
  const [ongoingCampaigns, setOngoingCampaigns] = useState<CampaignModel[]>([])
  const [expiredCampaigns, setExpiredCampaigns] = useState<CampaignModel[]>([])
  const { permissions, loaded } = usePermissions<permissionService.GroupPermission>()
  const { operator } = useViewOperator(permissionService.Permissions.VIEW_CAMPAIGN)
  const history = useHistory()

  useEffect(() => {
    if(operator) {
      dispatch(requestList(operator))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator])

  useEffect(() => {
    if(viewVersion > 0 && operator) {
      dispatch(requestList(operator))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion])

  useEffect(() => {
    const result = campaignList.filter(campaign => campaign.active)
    setOngoingCampaigns(result)
  }, [campaignList])

  useEffect(() => {
    const result = campaignList.filter(campaign => !campaign.active)
    setExpiredCampaigns(result)
  }, [campaignList])

  const newCampaignHandler = function() {
    alert('New campaign')
  }

  const hasAccess = permissionService.hasAny(permissionService.Permissions.VIEW_CAMPAIGN, permissions)

  if (!hasAccess && loaded) {
    history.replace('/')
  }

  return (
    <Authenticated location={location}>
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography variant='h5'>Dashboard</Typography>
            <div className={classes.headerActions}>
              <CampaignOperatorSelector />
              <Button
                disabled
                onClick={newCampaignHandler}
                className={classes.newCampaignButton}
                variant='contained'
                color='primary'
                startIcon={<AddIcon />}>New campaign</Button>
            </div>
          </div>
          <Divider />
        </div>
        <CampaignMetrics />

        <div className={classes.ongoingCampaigns}>
          <CampaignTable
            title='Ongoing campaigns'
            rows={ongoingCampaigns}
            action={
              <Button
                disabled
                onClick={newCampaignHandler}
                variant='outlined'
                color='primary'
                startIcon={<AddIcon />
                }>New campaign</Button>}/>
        </div>

        {expiredCampaigns.length > 0 &&
                    <div>
                      <CampaignTable
                        title='Expired campaigns'
                        rows={expiredCampaigns}/>
                    </div>
        }
      </div>
    </Authenticated>
  )
}

export default CampaignComponent
