import { usePermissions } from 'react-admin'
import { useMemo } from 'react'

import { Operator, useOperatorContext } from '@services/operator'
import { GroupType } from '@services/okta'
import { GroupPermission, Permissions } from '@services/permissions'

const useActiveOperators = (right: Permissions) => {
  const { permissions, loaded } = usePermissions<GroupPermission>()
  const { operators } = useOperatorContext()

  const operatorsByPermission = useMemo(
    () => {
      if (!loaded || !permissions) return []

      const filterByPermission = (operator: Operator): boolean => {
        const groupPermissions = permissions[operator.toUpperCase() as GroupType]
        return groupPermissions.includes(right)
      }

      return operators.filter(filterByPermission)
    }, 
    [operators, right, loaded, permissions]
  )

  return operatorsByPermission
}

export default useActiveOperators

