import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import CircularProgress from '@material-ui/core/CircularProgress'

import { requestList, requestMetricsOverall } from '@actions/campaigns'

import { campaignsSelector } from '@selectors/campaignSelector'

const CampaignRefreshButton = () => {
  const dispatch = useDispatch()
  const { inProgress, operator } = useSelector(campaignsSelector)
  const icon = inProgress ? <CircularProgress size={18} /> : <RefreshIcon />

  function handleClick() {
    if(operator) {
      dispatch(requestList(operator))
      dispatch(requestMetricsOverall(operator))
    }
  }

  return (
    <Button color='primary' disabled={!!inProgress} onClick={handleClick} startIcon={icon}>Refresh</Button>
  )
}

export default CampaignRefreshButton
