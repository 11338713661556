import startCase from 'lodash/startCase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Record } from 'react-admin'

const useStyles = makeStyles({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    columns: 2
  },
  label: {
    display: 'inline-block',
    fontWeight: 'bold',
    width: 150
  },
  value: {}
})

type CatalogRowPanelProps = Partial<{
   basePath: string;
   record: Record;
  resource: string;
}>

export const CatalogRowPanel = (props: CatalogRowPanelProps) => {
  const classes = useStyles()
  const { record } = props as Required<CatalogRowPanelProps>

  const items = Object.keys(record).sort().map((key, index) => {
    if (key === 'id') return null

    const label = startCase(key)
    const value = record[key]
    const val = Array.isArray(value) ? value.map(i => i.name || i).join(', ') : value.toString()
    return (
      <li key={index}>
        <span className={classes.label}>{ label }</span>
        <span className={classes.value}>{ val }</span>
      </li>
    )
  })

  return (
    <div>
      <Typography variant='h5' gutterBottom>Details</Typography>
      <ul className={classes.list}>
        {items}
      </ul>
    </div>
  )
}
