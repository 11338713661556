import { GetListParams, GetListResult, SortPayload, Record as ReactAdminRecord } from 'react-admin'
import { v4 as uuidv4 } from 'uuid'

import { emptyResponse, sortByField } from '@providers/data-provider'

import http from '@services/http'
import config from '@services/config'

import { Operator } from './operator'
import { appendGrandTotalRow, appendSummaryRows } from './reports'

interface GetSettlementReportOpts extends GetListParams {
  filter: {
    reportMonth: string,
    operator: Operator,
  },
  sort: SortPayload
}

interface MissingCallbacksRecord extends ReactAdminRecord {
  channel: string
  totalTransactions?: number,
  monthlyPayingUsers?: number,
  attemptedToPurchase?: number,
  currency: string,
  feePerUser?: number,
  total: number
}

type SettlementReportResponse = {
  operator: string
  currency: string
  from: string
  to: string
  summary: MissingCallbacksRecord[]
}

interface SettlementReportListResult extends ReactAdminRecord, MissingCallbacksRecord {}

export async function getMissingCallbacks(params: GetSettlementReportOpts): Promise<GetListResult<SettlementReportListResult>> {
  const {
    reportMonth,
    operator
  } = params.filter

  if(!reportMonth || !operator) return emptyResponse()

  const query = new URLSearchParams({ reportMonth })
  const url = `${config.api.url}/report/v1/${params.filter.operator}/settlement/missingcallbacks?${query.toString()}`

  return http.get<SettlementReportResponse>(url)
    .then(data => missingCallbacksMapper(data))
    .then(mapped => mapped.sort(sortByField.bind(null, params.sort)))
    .then(sorted => ({
      data: sorted,
      total: sorted.length
    }))
    .then(results => {
      const summaryRows = appendSummaryRows<MissingCallbacksRecord>(params.sort.field)(results.data)

      return {
        data: summaryRows,
        total: summaryRows.length,
      }
    })
    .then(results => {
      const withGrandTotal = appendGrandTotalRow<MissingCallbacksRecord>(results.data)

      return {
        data: withGrandTotal,
        total: withGrandTotal.length,
      }
    })
    .catch(() => emptyResponse())
}

function missingCallbacksMapper(data: SettlementReportResponse) {
  return data.summary
    .map((item) => ({
      ...item,
      id: uuidv4(),
      currency: data.currency
    }))
}
