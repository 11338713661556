import { useState, Fragment, useEffect, ChangeEventHandler, CSSProperties } from 'react'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import formatDate from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'

import { Permissions } from '@services/permissions'

import CampaignRowItemMenu from '@components/campaign/CampaignRowItemMenu'
import CampaignRefreshButton from '@components/campaign/CampaignRefreshButton'
import TableFilter from '@components/TableFilter'

import { useViewOperator } from '@hooks/useViewOperator'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      cursor: 'pointer'
    },
  },
  cellActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'initial'
  },
  descriptionCell: {
    paddingBottom: 0,
    paddingTop: 0,
    background: '#e3e3e3',
    boxShadow: [['inset', 0, 2, 4, 'rgba(0, 0, 0, 0.08)']] as unknown as CSSProperties['boxShadow']
  },
  campaignDescription: {
    whiteSpace: 'pre-line'
  }
})

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  toolbarButtons: {
    display: 'flex',
    '& button': {
      marginLeft: 10
    }
  },
  rowsNumber: {
    color: '#ccc',
    fontSize: '0.8em'
  },
  tableCellHead: {
    fontWeight: 'bold'
  }
}))

export type Campaign = {
    id: string
    name: string
    applications: string[]
    accountTypes: string[]
    changed: number
    description: string
    active?: boolean
}

type CampaignRowProps = {
  row: Campaign
}

const Row = (props: CampaignRowProps) => {
  const { row } = props
  const [open , setOpen] = useState(false)
  const classes = useRowStyles()

  useEffect(() => {
    setOpen(false)
  }, [row])

  return (
    <Fragment>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell>
          {row.description && (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{Array.isArray(row.applications) ? row.applications.join(', ') : row.applications}</TableCell>
        <TableCell>{Array.isArray(row.accountTypes) ? row.accountTypes.join(', ') : row.accountTypes}</TableCell>
        <TableCell align='right'>{formatDate(new Date(row.changed), 'dd/MM/yyyy')}</TableCell>
        <TableCell align='right' className={classes.cellActions}>
          <CampaignRowItemMenu />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.descriptionCell} colSpan={7}>
          <Collapse in={open} timeout='auto'>
            <Box margin={1} className={classes.campaignDescription}>
              {row.description}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

type CampaignTableProps = {
  rows: Campaign[]
  title: string,
  action?: React.ReactNode
}

const CampaignTable = ({ rows, title, action }: CampaignTableProps) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [channelFilterRows, setChannelFilterRows] = useState<Campaign[]>([])
  const [customerFilterRows, setCustomerFilterRows] = useState<Campaign[]>([])
  const [filteredRows, setFilteredRows] = useState<Campaign[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { operator } = useViewOperator(Permissions.VIEW_CAMPAIGN)

  const handleChangePage = (_ev: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage: ChangeEventHandler<{ value: string }> = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const filterRows = (allowedValues: string[], column: 'applications' | 'accountTypes') => 
    rows.filter(row => {
      if(!row[column]) {
        return allowedValues.includes('<Empty>')
      }
      return row[column].find(value => allowedValues.includes(value))
    })

  const handleChannelFilter = (allowedValues: string[]) => {
    const filtered = filterRows(allowedValues, 'applications')
    setChannelFilterRows(filtered)
  }

  const handleCustomerFilter = (allowedValues: string[]) => {
    const filtered = filterRows(allowedValues, 'accountTypes')
    setCustomerFilterRows(filtered)
  }

  const getUniqueValues = (rows: Campaign[], column: 'applications' | 'accountTypes') => Array.from(
    new Set(rows.flatMap(row => row[column] || '<Empty>'))
  )

  useEffect(() => {
    const rowsIntersection = channelFilterRows
      .filter(row =>
        !!customerFilterRows.find(cRow => row.id === cRow.id)
      )
    setFilteredRows(rowsIntersection)
    setPage(0)
  }, [channelFilterRows, customerFilterRows])

  useEffect(() => {
    if(operator) {
      setPage(0)
    }
  }, [operator])

  useEffect(() => {
    setFilteredRows(rows)
    setChannelFilterRows(rows)
    setCustomerFilterRows(rows)
  }, [rows])

  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <div>
          <Typography>{title} <span className={classes.rowsNumber}>{rows.length} in total </span></Typography>
        </div>
        <div className={classes.toolbarButtons}>
          <CampaignRefreshButton />
          { action }
        </div>
      </Toolbar>
      <Divider />
      <TableContainer>
        {
          rows.length
            ? <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell width='40%' className={classes.tableCellHead}>Campaign name</TableCell>
                  <TableCell width='20%' className={classes.tableCellHead}>Channel \
                    <TableFilter
                    // Hack to force component remount
                      key={`${rows[0].id}-1`}
                      values={getUniqueValues(rows, 'applications')}
                      onChange={handleChannelFilter}/></TableCell>
                  <TableCell width='20%' className={classes.tableCellHead}>Customers <TableFilter
                    key={`${rows[0].id}-2`}
                    values={getUniqueValues(rows, 'accountTypes')}
                    onChange={handleCustomerFilter}/></TableCell>
                  <TableCell width='13%' className={classes.tableCellHead} align='right'>Changed</TableCell>
                  <TableCell width='5%' className={classes.tableCellHead} align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, ix) => <Row row={row} key={ix}/>)}
              </TableBody>
            </Table>
            : null
        }
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default CampaignTable
