import { Operator } from '@services/operator'

import { ActionPayload } from '@actions/types'

export const REQUEST_LIST = 'CAMPAIGNS/REQUEST_LIST'
export const RECEIVE_LIST = 'CAMPAIGNS/RECEIVE_LIST'
export const ERROR_LIST = 'CAMPAIGNS/ERROR_LIST'

export const REQUEST_METRICS_OVERALL = 'CAMPAIGNS/REQUEST_METRICS_OVERALL'
export const RECEIVE_METRICS_OVERALL = 'CAMPAIGNS/RECEIVE_METRICS_OVERALL'
export const ERROR_METRICS_OVERALL = 'CAMPAIGNS/ERROR_METRICS_OVERALL'

export type RequestListActionPayload = ActionPayload<'CAMPAIGNS/REQUEST_LIST', { payload: { operator: Operator }}>

export const requestList = (operator: Operator): RequestListActionPayload => {
  return {
    type: REQUEST_LIST,
    payload: {
      operator
    }
  }
}

export type ReceiveListActionPayload = ActionPayload<'CAMPAIGNS/RECEIVE_LIST', { payload: unknown }>

export const receiveList = (payload: unknown): ReceiveListActionPayload => {
  return {
    type: RECEIVE_LIST,
    payload
  }
}

export type ErrorListActionPayload = ActionPayload<'CAMPAIGNS/ERROR_LIST', { error: Error }>

export const errorList = (error: Error): ErrorListActionPayload=> {
  return {
    type: ERROR_LIST,
    error
  }
}

export type RequestMetricsActionPayload = ActionPayload<'CAMPAIGNS/REQUEST_METRICS_OVERALL', { payload: { operator: Operator} }>

export const requestMetricsOverall = (operator: Operator): RequestMetricsActionPayload => {
  return {
    type: REQUEST_METRICS_OVERALL,
    payload: {
      operator
    }
  }
}

export type ReceiveMetricsOverallActionPayload = ActionPayload<'CAMPAIGNS/RECEIVE_METRICS_OVERALL', { payload: unknown }>

export const receiveMetricsOverall = (payload: unknown): ReceiveMetricsOverallActionPayload => {
  return {
    type: RECEIVE_METRICS_OVERALL,
    payload
  }
}

export type ErrorMetricsOverallActionPayload = ActionPayload<'CAMPAIGNS/ERROR_METRICS_OVERALL', { error : Error }>

export const errorMetricsOverall = (error: Error): ErrorMetricsOverallActionPayload => {
  return {
    type: ERROR_METRICS_OVERALL,
    error
  }
}

export type CampaignActionPaylods = 
  ErrorMetricsOverallActionPayload |
  ReceiveMetricsOverallActionPayload |
  RequestMetricsActionPayload |
  ErrorListActionPayload |
  ErrorListActionPayload |
  ReceiveListActionPayload |
  RequestListActionPayload
