import { DataProvider, GetListParams, GetListResult, SortPayload } from 'react-admin'

import { getSettlementReport } from '@services/settlement-report'
import { getPurchaseReport } from '@services/purchase-report'
import { getCatalog } from '@services/catalog'
import { getMissingCallbacks } from '@services/missing-calbacks'

type HandlerType = (params: GetListParams) => Promise<GetListResult<any>>
type ValueType = string|number|boolean

const handlers: Record<string, HandlerType> = {
  summary: getSettlementReport,
  missingCallbacks: getMissingCallbacks,
  'purchase-lookup': getPurchaseReport,
  catalog: getCatalog
}

const comparator = new Intl.Collator().compare

function isNumberPair(a: ValueType, b: ValueType) {
  return isNumber(a) && isNumber(b)
}

function isNumber(str: ValueType) {
  const num = parseFloat(str as string)

  if(Number.isNaN(num)) {
    return false
  }

  return num.toString().length === str.toString().length
}

export function sortByField(
  { field, order }: SortPayload, 
  a: Record<string, ValueType>,
  b: Record<string, ValueType>
): number {
  const left = a[field]
  const right = b[field]

  if(isNumberPair(left, right)) {
    return order === 'ASC' ? +left - +right : +right - +left
  }

  return order === 'ASC' 
    ? comparator(left as string, right as string) 
    : comparator(right as string, left as string)
}

export function emptyResponse<T = unknown>(): { data: T[], total: 0 } {
  return {
    data: [],
    total: 0
  }
}

const dataProvider: Partial<DataProvider> = {
  getList: (resource, params) => handlers[resource](params)
}

export default dataProvider as DataProvider
