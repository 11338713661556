import { CSSProperties, useEffect } from 'react'
import {
  parse,
  format as formatDate } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useListContext } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'

import { requestVersions} from '@actions/catalog'

import { catalogSelector } from '@selectors/catalogSelector'
import { adminUISelector } from '@selectors/reactAdminSelector'

import { FilterListContext } from './CatalogFilters'

const useStyles = makeStyles({
  main: {
    marginLeft: 3,
    width: (props: CatalogVersionProps) => props.width,
    textAlign: 'center',
    display: 'inline-block'
  },
  spinner: {
    padding: (props: CatalogVersionProps) => props.spinnerPadding as unknown as CSSProperties['padding']
  }
})

export const formatCatalogVersion = (alias: string, template: string): string => {
  if (!alias) return 'unknown'

  const parts = alias.split('_')
  const timestamp = parts[parts.length - 1]
  const date = parse(timestamp, 't', new Date())

  return formatDate(date, template)
}

type CatalogVersionProps = {
  width: number
  spinnerPadding: number[][]
  type: 'previous' | 'current'
  spinnerSize: number
}

export default function CatalogVersion(props: CatalogVersionProps) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { filterValues: { operator, catalog }} = useListContext() as FilterListContext
  const {
    inProgress,
    currentVersion,
    previousVersion 
  } = useSelector(catalogSelector)

  const { viewVersion } = useSelector(adminUISelector)
  const version = props.type === 'previous' ? previousVersion : currentVersion

  useEffect(() => {
    if(operator) {
      dispatch(requestVersions(operator, catalog))
    }
  }, [operator, catalog, dispatch])

  useEffect(() => {
    if(viewVersion > 0 && operator) {
      dispatch(requestVersions(operator, catalog))
    }
  }, [viewVersion, dispatch, operator, catalog])

  return (
    <span className={classes.main}>
      {inProgress
        ? <CircularProgress className={classes.spinner} size={props.spinnerSize} />
        : <span title={formatCatalogVersion(version, 'dd-MM-yyyy H:mm')}>
          {formatCatalogVersion(version, 'dd-MM-yyyy')}
        </span>
      }
    </span>
  )
}
