import { Record as ReactAdminRecord } from 'react-admin'
import { groupBy, isNumber } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

type CreateSummaryRowOpts<T> = {
  id: string, 
  channel: string, 
  fundsource: string, 
  rows?: T[],
  isGrandTotal?: boolean,
  isSummaryRow?: boolean,
}

export const createSummaryRow = <T extends ReactAdminRecord>({ id, channel, fundsource, rows = [], isGrandTotal, isSummaryRow }: CreateSummaryRowOpts<T>): T => {
  const summaryRow = rows.reduce((acc: T, next: T) => {
    Object
      .entries(next)
      .forEach(([key, value]) => {
        if (acc[key] === null) return

        //@ts-ignore
        acc[key] = isNumber(value) && (typeof acc[key] === 'undefined' || isNumber(acc[key]))
          ? (acc[key] || 0) + value 
          : null
      })

    return acc
  }, {} as T)

  return {
    ...summaryRow,
    id,
    channel,
    fundsource,
    isGrandTotal,
    isSummaryRow
  }
}

export const appendGrandTotalRow = <T extends ReactAdminRecord>(rows: T[]): T[] => [
  ...rows,
  createSummaryRow({
    id: uuidv4(),
    channel: 'Grand Total',
    fundsource: '',
    isGrandTotal: true,
    rows: rows.filter(row => !row.isSummaryRow),
  })
]

export const appendSummaryRows = <T extends ReactAdminRecord>(filterField: string, allowedFields: string[] = ['channel', 'fundsource']) => (rows: T[]): T[] => {
  if(!allowedFields.includes(filterField)) return rows
  const groupedByFilter = groupBy(rows, filterField)

  const result = rows.reduce((acc, current, index) => {
    const next = rows[index + 1]

    return [
      ...acc,
      current,
      current[filterField] !== next?.[filterField] && createSummaryRow({
        id: uuidv4(),
        channel: '',
        fundsource: '',
        isSummaryRow: true,
        rows: groupedByFilter[current[filterField]]
      })
    ].filter(Boolean) as T[]
  }, [] as T[])

  return result
}
