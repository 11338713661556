import { CSSProperties, useState } from 'react'
import color from 'color'
import { useInput, defaultTheme, InputProps } from 'react-admin'
import DateFnsUtils from '@date-io/date-fns'
import {
  sub as subtractDate,
  format,
  isAfter,
  parseISO,
  differenceInDays,
} from 'date-fns'
import { createTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { makeStyles, withStyles, createStyles, ThemeProvider, Theme } from '@material-ui/core/styles'
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker'

import CalendarDayComponent from '@components/CalendarDay'
import RangeDateToolbarComponent from '@components/RangeDateToolbar'

const useStyles = makeStyles({
  picker: {
    marginBottom: 8
  }
})

const styles = createStyles((theme: Theme) => ({
  dayWrapper: {
    position: 'relative',
    border: '2px solid transparent'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    color: 'inherit',
    transform: 'scale(1.12)'
  },
  daySelected: {
    background: theme.palette.primary.main,
    color: [theme.palette.common.white, '!important'] as unknown as CSSProperties['color'],
    borderRadius: '50%',
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  wrapperSelected: {
    border: `2px dashed ${theme.palette.text.disabled}`,
    borderLeftColor: 'rgba(0, 0, 0, 0.05)',
    borderRightColor: 'transparent',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  wrapperInterval: {
    background: color(theme.palette.primary.main).alpha(0.7).rgb().string(),
    color: theme.palette.common.white,
  },
  wrapperSelectedFirst: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  wrapperSelectedLast: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  hoverBetween: {
    border: `2px dashed ${theme.palette.text.disabled}`,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
  },
  hoverEnd: {
    border: `2px dashed ${theme.palette.text.disabled}`,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderLeftColor: 'transparent'
  }
}))

const themeOverrides = createTheme(defaultTheme, {
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 350
      },
    },
    MuiPickersCalendar: {
      week: {
        margin: '3px 0'
      },
      transitionContainer: {
        minHeight: 261
      }
    }
  },
  palette: {
    primary: {
      main: '#0F284C'
    }
  }
})

export const MAX_SELECTABLE_DAYS = 7
// workaround for not working defaultValues from filter
export const defaultStartDate = new Date(subtractDate(new Date(), { days: MAX_SELECTABLE_DAYS - 1 }))
export const defaultEndDate = new Date()

export function formatDate(date: Date) {
  return `${format(date, 'y-MM-dd')}`
}

export function decodeDate(input: string) {
  const [start, end] = input.split('_')
  return start && end ? [parseISO(start), parseISO(end)] : [undefined, undefined]
}

export function encodeDate(startDate: Date, endDate: Date) {
  return formatDate(startDate) + '_' + formatDate(endDate)
}

type RangeDateInputProps = {
  label: string
  options: [] 
} & InputProps

const RangeDateInput = ({ label, options, ...props }: RangeDateInputProps) => {
  const { input } = useInput(props)
  const [startDate, endDate] = decodeDate(input.value)
  const [ currentDate, setCurrentDate ] = useState<Date|null>()
  const [ firstDay, setFirstDay ] = useState<Date|null>(startDate || defaultStartDate)
  const [ lastDay, setLastDay ] = useState<Date|null>(endDate || defaultEndDate)
  const [ hoverDay, setHoverDay ] = useState<Date|null>(null)
  const [ currentSelection, setCurrentSelection ] = useState<'first'|'last'>('first')
  const classes = useStyles()

  const changeHandler: BasePickerProps['onChange'] = value => {
    setCurrentDate(value)
    input.onChange(encodeDate(firstDay!, lastDay!))
  }

  function hoverHandler(date: Date) {
    if(firstDay && !lastDay) {
      setHoverDay(date)
    }
  }

  function dayClickHandler(value: Date) {
    if(currentSelection === 'first') {
      const diff = lastDay ? differenceInDays(value, lastDay) : 0
      if(isAfter(value, lastDay!)) {
        setFirstDay(value)
        setLastDay(null)
        setCurrentSelection('last')
        return
      }
      if(Math.abs(diff) < MAX_SELECTABLE_DAYS) {
        setFirstDay(value)
        setCurrentSelection('last')
      }
    }

    if(currentSelection === 'last' && isAfter(value, firstDay!)) {
      const diff = differenceInDays(value, firstDay!)
      if(diff < MAX_SELECTABLE_DAYS) {
        setCurrentSelection('first')
        setLastDay(value)
      }
    }
  }

  const labelFormatter = () => firstDay && lastDay
    ? `${format(firstDay, 'MMM d')} - ${format(lastDay, 'MMM d')}`
    : 'Choose date range'

  const dayRenderer = (date: Date, selectedDate: Date, dayInCurrentMonth: number) => {
    const { classes } = props

    return (
      <CalendarDayComponent
        date={date}
        hoverDay={hoverDay}
        classes={classes}
        dayInCurrentMonth={dayInCurrentMonth}
        onClick={dayClickHandler.bind(null, date)}
        onMouseEnter={hoverHandler.bind(null, date)}
        selected={{first: firstDay, last: lastDay}} />
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={themeOverrides}>
        <DatePicker
          { ...options }
          label={label}
          value={currentDate}
          onChange={changeHandler}
          renderDay={dayRenderer}
          className={classes.picker}
          labelFunc={labelFormatter}
          ToolbarComponent={RangeDateToolbarComponent.bind(null, {
            firstDay,
            lastDay,
            currentSelection,
            setFirstDayActive: () => {
              setCurrentSelection('first')
            },
            setLastDayActive: () => {
              setCurrentSelection('last')
            },
            clearFirstDay: () => {
              setFirstDay(null)
              setLastDay(null)
              setCurrentSelection('first')
            },
            clearLastDay: () => {
              setLastDay(null)
              setCurrentSelection('last')
            }
          })}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default withStyles(styles)(RangeDateInput)
