import { Permissions } from '@services/permissions'

import useActiveOperators from '@hooks/useActiveOperators'
import { useActiveOperator } from '@hooks/useActiveOperator'

export const useViewOperator = (permission: Permissions) => {
  const viewOperators = useActiveOperators(permission)
  const { setOperator, operator } = useActiveOperator(permission, viewOperators[0])

  return {
    operator,
    setOperator,
    viewOperators,
  }
}
