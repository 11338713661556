import { MouseEventHandler, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'

const CampaignRowItemMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null)
  const open = Boolean(anchorEl)

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose: MouseEventHandler<HTMLLIElement> = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton size='small' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled key='edit' onClick={handleClose}>
          Edit
        </MenuItem>
        <MenuItem disabled key='delete' onClick={handleClose}>
          Delete
        </MenuItem>
      </Menu>
    </div>

  )
}

export default CampaignRowItemMenu
