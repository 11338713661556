import { useState } from 'react'
import { Button as RAButton, useListContext, useNotify, useRefresh } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'

import { uploadFile, uploadLink } from '@services/catalog'

import { GSheetLink } from '@components/catalog/GSheetLink'
import { FileUpload, FileUploadData } from '@components/catalog/FileUpload'

import { FilterListContext } from './CatalogFilters'

const useStyles = makeStyles({
  dialogTitle: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    right: 20,
    top: 12
  },
})

const CatalogUpload = () => {
  const classes = useStyles()

  const [file, setFile] = useState<FileUploadData>(null)
  const [gsheetLink, setGsheetLink] = useState('')

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const { filterValues } = useListContext() as FilterListContext
  const notify = useNotify()
  const refreshList = useRefresh()

  function handleOpen() {
    setIsDialogOpen(true)
  }

  function handleCancel() {
    setIsDialogOpen(false)
    clearForm()
  }

  function clearForm() {
    setFile(null)
    setGsheetLink('')
  }

  function handleUpload() {
    const payload = {
      operator: filterValues.operator,
      catalog: filterValues.catalog,
    }

    const uploadPromise = file 
      ? uploadFile({ 
        ...payload,
        file: file as NonNullable<Required<FileUploadData>> // 
      })
      : uploadLink({ ...payload, link: gsheetLink })

    setInProgress(true)

    uploadPromise
      .then(() => {
        setIsDialogOpen(false)
        clearForm()
        notify('Catalog has been updated successfully', 'success')

        setTimeout(() => {
          refreshList()
        }, 250)
      })
      .catch(error => {
        const errorResponse = JSON.parse(error.body)
        notify(errorResponse.message, 'error')
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const isUploadButtonDisabled = () => !(file?.blob || gsheetLink) || inProgress

  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason !== 'backdropClick') return

    setIsDialogOpen(false) 
  }

  return (
    <div>
      <RAButton
        onClick={ handleOpen }
        label='Upload new version'>
        <CloudUploadIcon />
      </RAButton>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}>
          Upload catalog
          {inProgress ? <CircularProgress className={classes.progress} /> : '' }
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Here you can upload new version of catalog as 
            <strong>.csv</strong> file or as link to Google Sheets. The entire catalog will be overwritten. 
            Keep in mind, when upload is successful new plans, txts and prices will be instantly available on the service.
          </DialogContentText>
          <FileUpload data={[file, setFile]} isDisabled={!!gsheetLink} />
          <GSheetLink data={[gsheetLink, setGsheetLink]} isDisabled={!!file} />
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={handleCancel}>
            Cancel
          </Button>
          <Button 
            disabled={isUploadButtonDisabled()} 
            variant='contained' 
            color='primary' 
            onClick={handleUpload} 
            disableElevation
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  )
}

export default CatalogUpload
