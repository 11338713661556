import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    marginBottom: 10
  }
})

type ChangeLogCardProps = {
  date: string, 
  features: string[], 
  bugfixes: string[],
  onClick: React.MouseEventHandler<HTMLButtonElement> 
}

const ChangeLogCard = (props: ChangeLogCardProps) => {
  const { date, features, bugfixes } = props
  const classes = useStyles()

  return (
    <Card className={classes.card} elevation={2}>
      <CardHeader
        action={
          <IconButton aria-label='settings' onClick={ props.onClick }>
            <CloseIcon />
          </IconButton>
        }
        subheader={date}
      />
      <CardContent>
        <Typography variant='body2' component='div'>
          {!!features.length && (
            <div>
              <Typography variant='h6'>New features:</Typography>
              <ul>
                {features.map((feature, i) => <li key={i}>{feature}</li>)}
              </ul>
            </div>
          )}
          {!!bugfixes.length && (
            <div>
              <Typography variant='h6'>Bug fixes:</Typography>
              <ul>
                { bugfixes.map((bugfix, i) => <li key={i}>{ bugfix }</li>)}
              </ul>
            </div>
          )}            
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ChangeLogCard
