import { GetListParams, GetListResult, SortPayload, Record as ReactAdminRecord } from 'react-admin'
import { v4 as uuidv4 } from 'uuid'

import { emptyResponse, sortByField } from '@providers/data-provider'

import http from '@services/http'
import config from '@services/config'

import { Operator } from './operator'
import { appendGrandTotalRow, appendSummaryRows } from './reports'

interface GetSettlementReportOpts extends GetListParams {
  filter: {
    reportMonth: string,
    operator: Operator,
  },
  sort: SortPayload
}

export interface SettlementReportSummary extends ReactAdminRecord {
  channel: string
  totalTransactions?: number,
  monthlyPayingUsers?: number,
  attemptedToPurchase?: number,
  currency: string,
  feePerUser?: number,
  total?: number,
  isSummaryRow?: boolean,
  isGrandTotal?: boolean
}

type SettlementReportResponse = {
  operator: string
  currency: string
  from: string
  to: string
  summary: SettlementReportSummary[]
}

export interface SettlementReportListResult extends SettlementReportSummary {}

export async function getSettlementReport(params: GetSettlementReportOpts): Promise<GetListResult<SettlementReportListResult>> {
  const {
    reportMonth,
    operator
  } = params.filter

  if(!reportMonth || !operator) return emptyResponse()

  const query = new URLSearchParams({ reportMonth })
  const url = `${config.api.url}/report/v1/${params.filter.operator}/settlement/summary?${query.toString()}`

  return http.get<SettlementReportResponse>(url)
    .then(data => settlementReportMapper(data))
    .then(mapped => mapped.sort(sortByField.bind(null, params.sort)))
    .then(sorted => ({ data: sorted, total: sorted.length }))
    .then(results => {
      const summaryRows = appendSummaryRows<SettlementReportSummary>(params.sort.field)(results.data)

      return {
        data: summaryRows,
        total: summaryRows.length,
      }
    })
    .then(results => {
      const withGrandTotal = appendGrandTotalRow<SettlementReportSummary>(results.data)

      return {
        data: withGrandTotal,
        total: withGrandTotal.length,
      }
    })
}

export async function getSettlementReportAsZip(operator: Operator, reportMonth: string) {
  const query = new URLSearchParams({ reportMonth })
  const url = `${config.api.url}/report/v1/${operator}/settlement/transactions?${query.toString()}`

  return http.download(url, {
    fileName: [
      'settlement-report',
      operator.toUpperCase(),
      reportMonth
    ].join('_') + '.zip'
  })
}

function settlementReportMapper(data: SettlementReportResponse) {
  return data.summary
    .map((item) => ({
      ...item,
      id: uuidv4(),
      currency: data.currency
    }))
}
