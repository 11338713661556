import { call, put, takeLeading } from 'redux-saga/effects'
import { fetchStart, fetchEnd } from 'react-admin'

import { getCampaignList, getCampaignMetrics } from '@services/campaigns'
import { Operator } from '@services/operator'

import {
  type RequestMetricsActionPayload,
  type RequestListActionPayload,
  REQUEST_LIST,
  receiveList,
  errorList,
  REQUEST_METRICS_OVERALL,
  receiveMetricsOverall,
  errorMetricsOverall 
} from '@actions/campaigns'

export function* fetchList({ payload: { operator }}: { payload: { operator: Operator }}) {
  try {
    yield put(fetchStart())
    const response: {} = yield call(getCampaignList, operator)
    yield put(receiveList(response))
    yield put(fetchEnd())
  } catch(error) {
    yield put(errorList(error as Error))
    yield put(fetchEnd())
  }
}

export function* fetchMetrics({ payload: { operator }}: { payload: { operator: Operator }}) {
  try {
    yield put(fetchStart())
    const response: {} = yield call(getCampaignMetrics, operator)
    yield put(receiveMetricsOverall(response))
    yield put(fetchEnd())
  } catch(error) {
    yield put(errorMetricsOverall(error as Error))
    yield put(fetchEnd())
  }
}

export default function* root() {
  yield takeLeading<RequestMetricsActionPayload>(REQUEST_LIST, fetchList)
  yield takeLeading<RequestListActionPayload>(REQUEST_METRICS_OVERALL, fetchMetrics)

}
