import prettyBytes from 'pretty-bytes'

type QuotaFieldProps = {
  binary: boolean
  record?: Record<string, number>
  source: string
}

export function QuotaField({source, record = {}, binary = false}: QuotaFieldProps) {
  const value = record[source]
  const output = value ? prettyBytes(value, { binary }) : 'N/A'

  return (
    <span>{output}</span>
  )
}
