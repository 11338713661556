import { useSpring, animated } from '@react-spring/web'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { SpringConfig } from 'react-spring'

import { Metric } from '@services/campaigns'

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: theme.spacing(4)
  },
  name: {
    textTransform: 'uppercase',
  }
}))

export type CampaignMetricsItemProps = Metric & {
  currency?: string, 
  fractionDigits?: number
}

const CampaignMetricsItem = ({ value, name, description, currency = '', fractionDigits = 0 }: CampaignMetricsItemProps) => {
  const classes = useStyles()
  const parsedValue = value ? Number.parseFloat(value) : 0
  const numberFormatter = Intl.NumberFormat(navigator.language, 
    !currency.length 
      ? { maximumFractionDigits: 0 }
      : {
        maximumFractionDigits: fractionDigits,
        style: 'currency',
        currency
      }
  )

  const animatedValue = useSpring({
    from: { number: 0 },
    to: { number: parsedValue },
    config: {
      duration: 500,
      round: true
      // TS documentation differs from what is in the docs. round has a number type but in docs is a boolean
    } as unknown as SpringConfig 
  })

  return (
    <div className={classes.container}>
      <Typography variant='h4'>
        <animated.div>{animatedValue.number.to(v => numberFormatter.format(v))}</animated.div>
      </Typography>
      <div>
        <Typography className={classes.name} component='span' variant='overline'>{name}</Typography>
        <Tooltip title={description || ''}>
          <IconButton size='small' disableFocusRipple={true} disableRipple={true}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default CampaignMetricsItem
